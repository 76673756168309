export default {
  primary: {
    border: '1px solid',
    borderColor: 'primaryGrayLight',
    borderRadius: '4px',
  },
  mobileMenu: {
    backgroundColor: 'white',
    height: '100vh',
    width: [ '100%', '343px', null ],
    overflowY: 'scroll',
    textAlign: 'center',
    fontSize: '24px',
    padding: '16px',
    pointerEvents: 'auto',
    position: 'absolute',
    top: '80px',
    transform: 'translateX(-100%)',
    transition: 'all 300ms ease-in',
  },
  tabbedCard: {
    // padding: '5px 15px',
    mt: '15px',
    section: {
      borderTop: '2px solid',
      borderColor: 'primaryGrayLight',
      padding: '25px 22px',
    },
  },
  cartListItem: {
    minHeight: [ '160px' ],
    borderRadius: '4px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.25)',
    padding: [ '15px', '30px', '30px' ],
    mb: '24px',
    alignItems: [ 'initial', 'center' ],
    position: 'relative',
    checkout: {
      padding: [ 0 ],
      mb: '24px',
      alignItems: [ 'initial', 'center' ],
      position: 'relative',
      border: 'none',
    },
  },
  fundraiserList: {
    header: {
      alignItems: 'center',
      mt: '30px',
      justifyContent: 'space-between',
    },
    item: {
      flexDirection: [ 'column', 'row' ],
      border: '1px solid lightGray',
      boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.25)',
      transition: '200ms ease-in-out',
      ':hover': {
        boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.25)',
      },
      borderRadius: '4px',
      padding: '20px',
      marginBottom: '20px',
    },
  },
  productCardBR: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    height: 'fit-content',
    border: '1px solid lightGray',
    borderRadius: '4px',
    boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.25)',
    transition: '200ms ease-in-out',
    ':hover': {
      boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.25)',
    },
  },
  productCardDG: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    height: 'fit-content',
    border: '1px solid lightGray',
    borderRadius: '4px',
    boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.25)',
    transition: '200ms ease-in-out',
    ':hover': {
      boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.25)',
    },
  },
  productCardMD: {
    display: 'grid',
    gridTemplateColumns: [ '132px 1fr', '1fr' ],
    height: 'fit-content',
    border: '1px solid lightGray',
    borderRadius: '4px',
    boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.25)',
    transition: '200ms ease-in-out',
    ':hover': {
      boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.25)',
    },
  },
  productCardDetailsWrapperBR: {
    justifyContent: 'space-between',
    p: '0px 16px 16px',
    flexDirection: 'column',
    gap: '4px',
  },
  productCardDetailsWrapperDG: {
    justifyContent: 'space-between',
    p: '0px 16px 16px',
    flexDirection: 'column',
    gap: '4px',
  },
  productCardDetailsWrapperMD: {
    justifyContent: 'space-between',
    p: [ '16px 16px 16px 0px', '0px 16px 16px' ],
    flexDirection: 'column',
    gap: '4px',
  },
  productCardList: {
    p: '10px',
    display: 'grid',
    gridTemplateColumns: [ '1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)' ],
    gap: '10px',
  },
  fundraiserSeller: {
    border: '1px solid lightGray',
    borderRadius: '4px',
    boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.25)',
    transition: '200ms ease-in-out',
    ':hover': {
      boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.25)',
    },
    minHeight: [ '140px', '140px', '82px' ],
  },
  orderSummary: {
    variant: 'cards.primary',
    flexDirection: 'column',
    padding: '30px',
    maxWidth: [ 'none', 'none', '516px' ],
    minWidth: [ 'none', 'none', '516px' ],
    ml: [ 0, 0, '24px' ],
    position: [ 'relative', 'relative', 'relative' ],
  },
  avatar: {
    width: '100%',
    bg: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'white',
    borderRadius: '4px',
    height: '204px',
    mb: '12px',
  },
  step: {
    borderRadius: '4px',
    mt: '24px',
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.25)',
    heading: {
      padding: '8px 30px',
    },
    body: {
      padding: '33px 30px',
    },
  },
  fundraiserJoin: {
    padding: '40px 34px',
    maxWidth: '500px',
    textAlign: 'center',
    alignItems: 'center',
  },
  seller: {
    width: [ '100%', '100%', '600px' ],
    border: [ 'none', 'none', '1px solid' ],
    borderColor: [ null, null, 'primaryGrayMid' ],
    padding: [ '24px 0', '24px 0', '24px 50px' ],
    margin: '0 auto',
    alignItems: 'center',
    textAlign: 'center',
    miniStats: {
      border: [ '1px solid', '1px solid', 'none' ],
      boxShadow: [ '0 0 5px 0 rgba(0, 0, 0, 0.25)', '0 0 5px 0 rgba(0, 0, 0, 0.25)', 'none' ],
      borderColor: [ 'primaryGrayLight', 'primaryGrayLight', 'primaryGrayLight' ],
      padding: [ '24px 16px', '24px 16px', 0 ],
    },
    detail: {
      padding: [ 0, 0, '30px' ],
      width: '100%',
      flexDirection: 'column',
      border: [ 'none', 'none', '1px solid' ],
      borderColor: [ null, null, 'primaryGrayMid' ],
    },
  },
  success: {
    width: '100%',
    height: 'fit-content',
    border: 'solid 2px #ddd',
    borderRadius: '8px',
    boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.15)',
    p: 6,
  },
};
