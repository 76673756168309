import { useQuery } from 'react-query';

import { ProductCategory } from '../types/product';
import * as QueryKeys from '../../../constants/QueryKeys';
import { fetchProductCategoriesByFundraiserType } from '../api';

import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';

export const useFetchCategoriesByFundraiserType = () => {
  const { fundraiserType } = useFundraiserState();

  return useQuery<ProductCategory[], ''>(QueryKeys.ProductCategories,
    () => fetchProductCategoriesByFundraiserType(fundraiserType), {
      refetchOnWindowFocus: false,
      retry: false,
    });
};

