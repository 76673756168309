/* eslint-disable max-len */
import { useMemo } from 'react';

import {
  FundraiserListItem,
  FinalOrderStatus,
} from 'features/fundraiser/types/fundraiser';

export const useFundraiserFinalOrderStatus =
  (fundraiser: FundraiserListItem | undefined): FinalOrderStatus => useMemo(() => fundraiser?.FinalOrderDetails?.Status ?? FinalOrderStatus.Undefined, [ fundraiser ]);
