import React, { useState } from 'react';
import { useTranslation } from '@lce/i18n';
import * as Yup from 'yup';
import {
  Box, Button, Text, Form, Flex, Option,
} from '@lce/slice_v2';
import _map from 'lodash/map';

import { PaymentForm } from './PaymentForm';
import ViewPayment from './ViewPayment';

import validateCreditCard from 'features/checkout/utils/validateCreditCard';
import { Step, Mode } from 'ui/common';
import { Address, useFetchStates } from 'features/common';
import {
  useCheckoutDispatch, useCheckoutState, usePaymentDispatch, usePaymentState,
} from 'features/checkout';
import { theme } from 'theme';
import { GoogleEvents } from 'features/analytics';
import { FundraiserType } from 'features/fundraiser';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';

export interface IPaymentProps {
  onEdit: () => void;
  onPaymentInfoComplete: () => void;
  mode: Mode;
  isCouponItemExist: boolean | undefined;
  date?: Date;
}

export interface IStaticBillingProps {
  address: Address;
}

// eslint-disable-next-line max-lines-per-function
const Payment: React.FC<IPaymentProps> = ({
  mode, onEdit, onPaymentInfoComplete, isCouponItemExist, date = new Date(),
}) => {
  const [ t ] = useTranslation();
  const { fundraiserType } = useFundraiserState();

  const [ sameAsShipping, setSameAsShipping ] = useState<boolean>(false);
  const [ billingAddress, setBillingAddress ] = useState<Address>();
  const [ isProductNotAvailable, setIsProductNotAvailable ] = useState<boolean>(false);

  const { data: states } = useFetchStates(1);
  const dispatch = useCheckoutDispatch();
  const { checkout, errorCode: checkoutErrorCode, shippingAddress } = useCheckoutState();
  const paymentDispatch = usePaymentDispatch();
  const paymentState = usePaymentState();

  const errorCode = paymentState.code || checkoutErrorCode;
  const hasOnlyCouponItems = checkout?.Cart.every(cart => cart.IsFreeShipping === true);
  const showSameAsShipping = fundraiserType !== FundraiserType.Brochure && !hasOnlyCouponItems;

  const handlePaymentInfo = async(e) => {
    GoogleEvents.addPaymentInfo({
      cartTotal: checkout?.Subtotal || 0,
      products: _map(checkout?.Cart, item => ({
        name: item.ProductName,
        price: item.UnitPrice,
        id: item.ProductId.toString(),
        quantity: item.Quantity,
      })),
    });
    setBillingAddress(e);
    dispatch({ type: 'SET_BILLING_ADDRESS', address: e });
    onPaymentInfoComplete();

    const card = {
      cvvNum: e.cvvNum,
      expiryMonth: e.expiryMonth,
      expiryYear: e.expiryYear,
    };

    paymentDispatch({ type: 'SET_CARD', card });
    paymentDispatch({ type: 'SET_REVIEW_STEP' });
  };

  const cvvRegex = /^[0-9]{3,4}$/;

  const setBillingToShipping = (sameAsBilling) => {
    if (sameAsBilling && shippingAddress) {
      setBillingAddress(shippingAddress);
      setSameAsShipping(true);
    } else {
      setBillingAddress(undefined);
      setSameAsShipping(false);
      setIsProductNotAvailable(false);
    }
  };

  // For some reason when checked, the 'sameAsShipping' form control value is false...
  // I didn't have time to look into this but it seems backwards
  const validationSchema = Yup.object().shape({
    FirstName: Yup.string()
      .when('sameAsShipping', {
        is: false,
        then: Yup.string().required(t('payment.FirstNameReq')),
        otherwise: Yup.string(),
      }),
    ccNum: Yup.string()
      .trim()
      .test('test-card-number',
        t('payment.CardInvalid'),
        value => validateCreditCard(value))
      .required(t('payment.CardRequired')), // HERE
    expiryYear: Yup.string()
      .test('test-expiry-year', t('payment.YearInvalid'), (expiryYear) => {
        const currentYear = String(date.getFullYear()).slice(-2);
        if (!expiryYear) {
          return true;
        }
        return Number(expiryYear) >= Number(currentYear);
      })
      .required(t('payment.YearReq')),
    expiryMonth: Yup.string()
      .when('expiryYear', (expiryYear, schema, expiryMonth) => schema.test({
        test: (expiryMonth) => {
          const currentYear = String(date.getFullYear()).slice(-2);

          if (!expiryMonth) {
            return false;
          }
          if (!expiryYear) {
            return true;
          }

          return Number(expiryYear) === Number(currentYear)
            ? Number(expiryMonth) >= date.getMonth() + 1
            : true;
        },
        message: !expiryMonth.value ? `${ t('payment.MonthReq') }` : `${ t('payment.CardExpired') }`,
      })),
    cvvNum: Yup.string().matches(cvvRegex, t('payment.CVVInvalid')).required(t('payment.CVVRequired')),
    LastName: Yup.string()
      .when('sameAsShipping', {
        is: false,
        then: Yup.string().required(t('payment.LastNameReq')),
        otherwise: Yup.string(),
      }),
    Address1: Yup.string()
      .when('sameAsShipping', {
        is: false,
        then: Yup.string().required(t('payment.AddressReq')),
        otherwise: Yup.string(),
      }),
    City: Yup.string()
      .when('sameAsShipping', {
        is: false,
        then: Yup.string().required(t('payment.CityReq')),
        otherwise: Yup.string(),
      }),
    StateProvinceIso: Yup.string()
      .when('sameAsShipping', {
        is: false,
        then: Yup.string().required(t('payment.StateReq')),
        otherwise: Yup.string(),
      }),
    ZipPostalCode: Yup.string()
      .when('sameAsShipping', {
        is: false,
        then: Yup.string().required(t('payment.ZipCodeReq')),
        otherwise: Yup.string(),
      })
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, t('fundraiser.ZipPostalCodeInvalid')),
  });

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a
        aria-label="Scroll to payment details"
        href="paymentAnchor"
        id="paymentAnchor"
        style={ { scrollMarginTop: '130px' } }
      />
      <Step onEdit={ onEdit } showEdit={ mode === 'preview' } title={ t('checkout.PaymentLabel') }>
        <Form
          initialValues={ billingAddress }
          onSubmit={ handlePaymentInfo }
          reValidateMode="onSubmit"
          validationSchema={ validationSchema }
        >
          <Box sx={ { display: mode === 'active' ? 'block' : 'none' } } variant="cards.step.body">
            { paymentState.inlineError && (
              <Text data-testid="eprotect-error-message" sx={ { mb: '-8px', mt: '-4px' } } variant="text.errorMessage">
                {t(`payment.PaymentProcessingErrorMessages.${ errorCode }`)}
              </Text>
            )}
            <PaymentForm />
            <input
              hidden={ true } id="paypageRegistrationId" name="paypageRegistrationId" readOnly={ true }
              type="text"
            />
            <input
              hidden={ true } id="bin" name="bin" readOnly={ true }
              type="text"
            />
            <Text sx={ { mt: '30px' } } variant="text.header.form">
              {t('checkout.BillingAddress')}
            </Text>
            <Form.Field
              component={ Form.Input.Checkbox }
              data-testid="same-as-shipping"
              defaultChecked={ false }
              id="sameAsShipping"
              inline={ true }
              label={ t('checkout.SameAsShipping') }
              name="sameAsShipping"
              onChange={ e => setBillingToShipping(e.currentTarget.checked) }
              sx={ { mt: '32px', svg: { color: 'black' }, display: showSameAsShipping ? 'default' : 'none' } }
            />

            { sameAsShipping
              ? (<StaticBillingAddress address={ shippingAddress } />)
              : (
                <>
                  <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                    <Form.Field
                      autoComplete="billing given-name"
                      data-testid="first-name"
                      id="FirstName"
                      label="First Name *"
                      name="FirstName"
                      sx={ { marginRight: [ 0, '12px' ] } }
                      variant="forms.checkout.field"
                    />
                    <Form.Field
                      autoComplete="billing family-name"
                      data-testid="last-name"
                      id="LastName"
                      label="Last Name *"
                      name="LastName"
                      sx={ { marginLeft: [ 0, '12px' ] } }
                      variant="forms.checkout.field"
                    />
                  </Flex>
                  <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                    <Form.Field
                      autoComplete="billing address-line1"
                      data-testid="street-address"
                      id="BillingCityAddress1"
                      label="Street Address *"
                      name="Address1"
                      sx={ { marginRight: [ 0, '12px' ] } }
                      variant="forms.checkout.field"
                    />
                    <Form.Field
                      autoComplete="billing address-line2"
                      data-testid="street-address2"
                      id="BillingCityAddress2"
                      label="Apt / Suite / Other"
                      name="Address2"
                      sx={ { marginLeft: [ 0, '12px' ] } }
                      variant="forms.checkout.field"
                    />
                  </Flex>
                  <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                    <Form.Field
                      autoComplete="billing address-level2"
                      data-testid="city"
                      id="BillingCity"
                      label="City *"
                      name="City"
                      sx={ { mr: '6px' } }
                      variant="forms.checkout.field"
                    />
                    <Form.Field
                      autoComplete="billing address-level1"
                      component={ Form.Select }
                      data-testid="state"
                      id="BillingStateProvinceIso"
                      label="State *"
                      name="StateProvinceIso"
                      onChange={ (e) => {
                        if (e.target.value === 'AK' || e.target.value === 'HI') {
                          setIsProductNotAvailable(true);
                        } else {
                          setIsProductNotAvailable(false);
                        }
                      } }
                      sx={ { width: [ '100%', '156px' ], mr: [ 0, '12px' ], ml: [ 0, '12px' ] } }
                      variant="forms.checkout.field"
                    >
                      <Option value="">---</Option>
                      {states && states.map(state => (
                        <option key={ state.Id } value={ state.Abbreviation }>
                          {state.Abbreviation}
                        </option>
                      ))}
                    </Form.Field>
                    <Form.Field
                      autoComplete="billing postal-code"
                      data-testid="postal-code"
                      id="BillingCityZipPostalCode"
                      label="Zip / Postal Code *"
                      name="ZipPostalCode"
                      sx={ { ml: [ 0, '6px' ] } }
                      variant="forms.checkout.field"
                    />
                  </Flex>
                </>
              )}
            {isProductNotAvailable && isCouponItemExist && !sameAsShipping && (
              <Text sx={ { color: theme.colors.red, fontFamily: theme.fonts.lato } }>
                {t('checkout.ProductWarning') }
              </Text>
            )}
            <Button
              data-testid="payment-button"
              sx={ { width: '100%' } }
              title={ t('checkout.NextPaymentLabel') }
              type="submit"
              variant="primary"
            >
              {t('checkout.NextPaymentLabel')}
            </Button>
          </Box>
          <ViewPayment billingAddress={ billingAddress } showPayInfo={ mode === 'preview' } />
        </Form>
      </Step>
    </>

  );
};

const StaticBillingAddress: React.FC<IStaticBillingProps> = ({ address }) => (
  <Box sx={ { mb: '16px' } }>
    <Text>
      {address.FirstName}
      {' '}
      {address.LastName}
    </Text>
    <Text>
      {address.Address1}
      {' '}
      {address.Address2}
    </Text>
    <Text>
      {address.City}
      ,
      {' '}
      {address.StateProvinceIso}
      {' '}
      {address.ZipPostalCode}
    </Text>
  </Box>
);

export default Payment;
