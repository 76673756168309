import * as React from 'react';
import {
  Card, Box, Text, Button, Flex,
} from '@lce/slice_v2';
import { ThemeUIStyleObject } from 'theme-ui';

export type Mode = | 'preview' | 'hidden' | 'active';

export interface IStepProps {
  title: string;
  showEdit?: boolean;
  editText?: string;
  onEdit?: () => void;
  showCustom?: boolean;
  customText?: string;
  onCustom?: () => void;
  removeEditButton?: boolean;
  sx?: ThemeUIStyleObject;
}

const Step: React.FC<IStepProps> = ({
  title, showEdit, onEdit, children, editText = 'Edit', showCustom, customText, onCustom, removeEditButton = false, sx,
}) => (
  <Card sx={ sx } variant="cards.step">
    <Box
      as={ Flex }
      sx={ { bg: 'primaryGrayMid', justifyContent: 'space-between', alignItems: 'center' } }
      variant="cards.step.heading"
    >
      <Text variant="text.header.subUpper">
        {title}
      </Text>
      {showCustom && (
        <Button
          data-testid={ customText }
          onClick={ onCustom }
          sx={ { height: 'auto', textAlign: 'right' } }
          variant="text"
        >
          {customText}
        </Button>
      )}
      {(showEdit || removeEditButton) && (
        <Button
          data-testid={ customText }
          onClick={ onEdit }
          sx={ { visibility: showEdit ? 'visible' : 'hidden', height: 'auto', textAlign: 'right' } }
          variant="text"
        >
          {editText}
        </Button>
      )}
    </Box>
    {children}
  </Card>
);

export default Step;
