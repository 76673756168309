/* eslint-disable max-len */
import api from '../../api';

import { ProductCategory, Product } from './types/product';

import { FundraiserType } from 'features/fundraiser';

export const fetchProduct = async(sku: string) => api.get<Product>(`api/product/${ sku }`).then(res => res);

export const fetchProducts = async() => api.get<Product[]>('api/product').then(res => res);
export const fetchProductsByFundraiserType = async(fundraiserType: FundraiserType, fundraiserId?: number) => api.get<Product[]>(`api/product?fundraiserType=${ fundraiserType }&fundraiserId=${ fundraiserId }`).then(res => res);

export const fetchProductCategories = async() => api.get<ProductCategory[]>('api/category').then(res => res);
export const fetchProductCategoriesByFundraiserType = async(fundraiserType: FundraiserType) => api.get<ProductCategory[]>(`api/category?fundraiserType=${ fundraiserType }`).then(res => res);
