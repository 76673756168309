import * as React from 'react';
import {
  Box, Button, Flex, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { GiftMessaging } from './GiftMessaging';

import { Step, Mode } from 'ui/common';
import { useCheckoutState } from 'features/checkout';
import { CartListItem } from 'ui/cart';
import { FundraiserType } from 'features/fundraiser';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { InlineLinkParseAndReplace } from 'ui/components';

export interface IOrderReviewProps {
  mode: Mode;
  onEdit: () => void;
  onPlaceOrder: () => void;
}

const OrderReview: React.FC<IOrderReviewProps> = ({
  mode, onEdit, onPlaceOrder,
}) => {
  const [ t ] = useTranslation();
  const { fundraiserType } = useFundraiserState();

  const checkout = useCheckoutState();

  return (
    <Step
      editText={ t('checkout.EditCart') }
      onEdit={ onEdit }
      showEdit={ mode === 'active' }
      title={ t('checkout.OrderReview.Label') }
    >
      <Box
        data-testid="order-review"
        sx={ { display: mode === 'active' ? 'block' : 'none' } }
        variant="cards.step.body"
      >
        <Flex sx={ { flexDirection: 'column' } }>
          { checkout && checkout.checkout &&
            checkout.checkout.Cart.map(item => (
              <CartListItem
                cartItem={ item }
                disableChanges={ true }
                key={ item.ProductId }
                variant="cartListItem.checkout"
              />
            ))}
          { fundraiserType !== FundraiserType.Brochure && <GiftMessaging />}
          <Button
            data-testid="place-order-button"
            disabled={ checkout.loading }
            onClick={ onPlaceOrder }
            variant={ checkout.loading ? 'disabled' : 'primary' }
          >
            { t('checkout.PlaceOrder') }
          </Button>
          <Text sx={ { mt: '10px', fontWeight: 'bold' } } variant="legalDisclaimer">
            { t('checkout.OrderReview.DeliveryInfo', { context: fundraiserType }) }
          </Text>
          <Text sx={ { mt: '10px' } } variant="legalDisclaimer">
            <InlineLinkParseAndReplace
              rel="noopener noreferrer"
              target="_blank"
              text={ t('checkout.OrderReview.Legal') }
              wordsToLink={ t('checkout.OrderReview.LegalLinks', { returnObjects: true }) }
            />
          </Text>
        </Flex>
      </Box>
    </Step>
  );
};

export default OrderReview;
