/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { map as _map } from 'lodash';
import { Flex, Box } from '@lce/slice_v2';
import { ChevronDown, ChevronUp } from 'react-feather';

import { useMediaQuery, useQueryParam } from 'features/common';
import { useFetchCategoriesByFundraiserType } from 'features/products';

export interface IProductCategorySelectorProps {
  isOpenDefault?: boolean;
}

const ProductCategorySelector: React.FC<IProductCategorySelectorProps> = ({
  isOpenDefault = false,
}) => {
  const [ isOpen, setIsOpen ] = useState<boolean>(isOpenDefault);
  const {
    data: categoryResult, isLoading: isCategortyResultLoading,
  } = useFetchCategoriesByFundraiserType();
  const [ activeCategory, setActiveCategory ] = useQueryParam('c');
  const setInitialCategory = useCallback(() => {
    if (categoryResult) {
      const categoryExists = categoryResult.find(c => c.Id === parseInt(activeCategory)) !== undefined;
      if (categoryExists) {
        setActiveCategory(parseInt(activeCategory));
      } else {
        setActiveCategory(categoryResult[0].Id);
      }
    }
  }, [ categoryResult, setActiveCategory, activeCategory ]);

  useEffect(() => {
    setInitialCategory();
  }, [ setInitialCategory ]);

  const isLarge = useMediaQuery('(min-width: 960px)');

  const selectCategoryHandlerDesktop = (categoryId: number) => {
    setIsOpen(false);
    if (activeCategory !== categoryId) {
      setActiveCategory(categoryId);
    }
  };

  const selectCategoryHandlerMobile = (categoryId: number) => {
    if (activeCategory === categoryId) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(false);
      setActiveCategory(categoryId);
    }
  };

  return !isCategortyResultLoading
    ? isLarge
      ? (
        <DesktopNav
          categories={ categoryResult }
          currentCategory={ activeCategory }
          onClick={ selectCategoryHandlerDesktop }
        />
      )
      : (
        <MobileNav
          categories={ categoryResult }
          currentCategory={ activeCategory }
          isOpen={ isOpen }
          onClick={ selectCategoryHandlerMobile }
        />
      )
    : null;
};

interface ICategory {
  Id: number;
  Name: string;
}

interface IDesktop {
  categories: ICategory[] | undefined;
  currentCategory: number;
  onClick: (value) => void;
}

const DesktopNav: React.FC<IDesktop> = ({
  categories, currentCategory, onClick,
}) => (
  <Flex data-testid="category-selector" sx={ { mb: '12px', ml: '12px', width: '100%' } }>
    {categories?.map((category) => {
      const selected = category.Id === currentCategory;
      return (
        <Flex
          data-testid={ `category-${ category.Id }${ selected ? '-selected' : '' }` }
          key={ category.Id }
          onClick={ () => onClick(category.Id) }
          sx={ {
            mr: '48px',
            fontSize: '22px',
            fontWeight: 'bold',
            color: selected ? 'primaryOrange' : '#565555',
            cursor: 'pointer',
          } }
        >
          {category.Name}
        </Flex>
      );
    }) }
  </Flex>
);

interface IMobileNav {
  categories: ICategory[] | undefined;
  currentCategory: number;
  isOpen: boolean;
  onClick: (value) => void;
}

// eslint-disable-next-line max-lines-per-function
const MobileNav: React.FC<IMobileNav> = ({
  categories, currentCategory, isOpen, onClick,
}) => (
  <Flex
    data-testid="mobile-category-selector" sx={ {
      justifyContent: 'center', position: 'relative', m: '0 auto', zIndex: 800,
      '*div': { zIndex: 10 }, border: isOpen ? '1px solid transparent' : '1px solid #808080',
    } }
    variant="links.dashboardMobileNav"
  >

    <Box
      data-testid="not-open-nav"
      onClick={ () => onClick(currentCategory) }
      sx={ {
        color: 'primaryOrange', fontWeight: 'bold', cursor: 'pointer',
        bg: 'white', display: 'flex', justifyContent: 'space-between', p: '10px', borderRadius: '8px',
      } }
      variant="links.dashboardNavLink"
    >
      {categories?.find(c => c.Id === currentCategory)?.Name}
      <Box sx={ { zIndex: 800 } }>
        {isOpen
          ? (
            <ChevronUp
              color="#FF671B" data-testid="chevron-not-open" height="16px"
              onClick={ () => onClick(currentCategory) } strokeWidth="3" width="16px"
            />
          )
          : (
            <ChevronDown
              color="#FF671B" data-testid="chevron-open" height="16px"
              onClick={ () => onClick(currentCategory) } strokeWidth="3" width="16px"
            />
          )}
      </Box>
    </Box>

    {isOpen && (
      <Box
        data-testid="open-nav"
        sx={ {
          position: 'absolute', left: 0, width: '100%',
          boxShadow: '0 0 5px rgba(0, 0, 0, 0.25)',
          bg: 'white', borderRadius: '8px', border: '1px solid #808080',
        } }
      >
        {_map(categories, (category) => {
          const selected = category.Id === currentCategory;
          return (
            <Box
              data-testid={ `category-${ category.Id }${ selected ? '-selected' : '' }` }
              key={ category.Id }
              onClick={ () => onClick(category.Id) }
              sx={ {
                fontSize: '22px',
                fontWeight: 'bold',
                color: selected ? 'primaryOrange' : '#565555',
              } }
              variant="links.categorySelectorLink"
            >
              {category.Name}
            </Box>
          );
        })}
      </Box>
    )}
  </Flex>
);

export default ProductCategorySelector;
