/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Box } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { useOktaAuth } from '@okta/okta-react';

import { ShippingDetails, ShippingDetailsSummary } from '../ShippingDetails';
import { AddressConfirmModal } from '../CheckoutModals/AddressConfirmModal';
import { IShippingDetails, IDetailsSummaryProps } from '../ShippingDetails/types';

import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { Step, Mode } from 'ui/common';
import {
  useAddressValidation, useCheckoutDispatch, usePaymentDispatch,
} from 'features/checkout';
import { Address, useUserInfo } from 'features/common';

export interface IDetailsProps {
  onEdit: () => void;
  onVerified: () => void;
  mode: Mode;
  hasOnlyCouponItems: boolean | undefined;
  hasOnlyDonationItems?: boolean;
  isCouponItemExist: boolean | undefined;
  ShippingDetailsComponent?: React.FC<IShippingDetails>;
  ShippingDetailsSummaryComponent?: React.FC<IDetailsSummaryProps>;
  defaultShippingDetails?: Address;
  defaultAddressModalVisibility?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const Details: React.FC<IDetailsProps> = ({
  mode,
  onEdit,
  onVerified,
  hasOnlyCouponItems,
  hasOnlyDonationItems = false,
  isCouponItemExist,
  ShippingDetailsComponent = ShippingDetails,
  ShippingDetailsSummaryComponent = ShippingDetailsSummary,
  defaultShippingDetails,
  defaultAddressModalVisibility = false,
}) => {
  const [ t ] = useTranslation();
  const dispatch = useCheckoutDispatch();
  const paymentDispatch = usePaymentDispatch();
  const { fundraiserType } = useFundraiserState();

  const [ shippingDetails, setShippingDetails ] = useState<Address | undefined>(defaultShippingDetails);
  const [ suggestedAddress, setSuggestedAddress ] = useState<Address | undefined>(undefined);
  const { mutateAsync: validateAddress, isLoading } = useAddressValidation();
  const [ isAddressModalOpen, setIsAddressModalOpen ] = useState<boolean>(defaultAddressModalVisibility);

  const { authState } = useOktaAuth();
  const { data: user, isLoading: userLoading } = useUserInfo(authState.isAuthenticated);

  useEffect(() => {
    if (user) {
      const {
        Phone, FirstName, LastName, Email,
      } = user;

      setShippingDetails(shippingDetails => ({
        ...shippingDetails,
        Email,
        LastName,
        FirstName,
        PhoneNumber: Phone,
        ConfirmEmailAddress: Email,
      } as Address));
    }
  }, [ user ]);

  const onAddressConfirmHandler = (selection: string) => {
    const selectedAddress = selection === 'suggested' ? suggestedAddress : shippingDetails;
    if (selectedAddress) {
      dispatch({ type: 'SET_SHIPPING_ADDRESS', address: selectedAddress });
      setShippingDetails(selectedAddress);
      onVerified();
    }
  };

  const shippingDetailsHandler = async(details: Address, shouldValidateAddress: boolean | undefined) => {
    setShippingDetails(details);
    const data = shouldValidateAddress ? await validateAddress(details) : undefined;

    if (!data) {
      dispatch({ type: 'SET_SHIPPING_ADDRESS', address: details });
      setShippingDetails(details);
      onVerified();
      paymentDispatch({ type: 'SET_PAYMENT_STEP' });
    }

    if (data) {
      setSuggestedAddress(data?.ValidatedAddress);
      if (data.ShowSuggested) {
        setIsAddressModalOpen(true);
      } else {
        dispatch({ type: 'SET_SHIPPING_ADDRESS', address: details });
        setShippingDetails(details);
        onVerified();
        paymentDispatch({ type: 'SET_PAYMENT_STEP' });
      }
    }
  };

  return (
    <>
      { mode === 'active' && !userLoading && (
        <Step onEdit={ onEdit } title={ t('checkout.ShippingDetailsLabel', { context: fundraiserType }) }>
          <Box variant="cards.step.body">
            <ShippingDetailsComponent
              loading={ isLoading }
              setShippingDetails={ shippingDetailsHandler }
              shippingDetails={ shippingDetails }
            />
          </Box>
        </Step>
      )}

      { mode === 'preview' && (
        <Step
          onEdit={ onEdit }
          showEdit={ true }
          title={ t('checkout.ShippingDetailsLabel', { context: fundraiserType }) }
        >
          <Box variant="cards.step.body">
            { shippingDetails ? (
              <ShippingDetailsSummaryComponent
                details={ shippingDetails }
                hasOnlyCouponItems={ hasOnlyCouponItems }
                hasOnlyDonationItems={ hasOnlyDonationItems }
                isCouponItemExist={ isCouponItemExist }
              />
            ) : null}
          </Box>
        </Step>
      )}

      {isAddressModalOpen && (
        <AddressConfirmModal
          isOpen={ isAddressModalOpen }
          onClose={ () => {
            setIsAddressModalOpen(false);
            paymentDispatch({ type: 'SET_PAYMENT_STEP' });
          } }
          onSelect={ onAddressConfirmHandler }
          originalAddress={ shippingDetails }
          suggestedAddress={ suggestedAddress }
        />
      )}
    </>

  );
};

export default Details;
