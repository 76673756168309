import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useFundraiserDispatch, useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { FundraiserType } from 'features/fundraiser/types';

export const FundraiserState = ({ children }) => {
  interface LocationState {
    fundraiserType: FundraiserType | undefined;
  }
  const { pathname, search, state } = useLocation<LocationState>();
  const { fundraiserType } = useFundraiserState();

  const effectiveState = state?.fundraiserType ?? fundraiserType;

  const isMealDeal = pathname.includes('/meal-deal') ||
    search.includes('meal-deal') ||
    effectiveState === FundraiserType.MealDeal;

  const isBrochure = pathname.includes('/brochure') ||
    search.includes('brochure') ||
    effectiveState === FundraiserType.Brochure;

  const fundraiserDispatch = useFundraiserDispatch();

  useEffect(() => {
    if (isMealDeal) {
      fundraiserDispatch({ type: 'SET_FUNDRAISER_TYPE', fundraiserType: FundraiserType.MealDeal });
    } else if (isBrochure) {
      fundraiserDispatch({ type: 'SET_FUNDRAISER_TYPE', fundraiserType: FundraiserType.Brochure });
    } else {
      fundraiserDispatch({ type: 'SET_FUNDRAISER_TYPE', fundraiserType: FundraiserType.Digital });
    }
  }, [ fundraiserDispatch, isMealDeal, isBrochure ]);

  return children;
};
