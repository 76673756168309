import { useMemo } from 'react';
import { useTranslation } from '@lce/i18n';

import { FundraiserType } from 'features/fundraiser';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';

export const useProductDetailLink = (sku: string, supportGuid?: string) => {
  const { fundraiserType } = useFundraiserState();
  const [ t ] = useTranslation();

  return useMemo(() => {
    let productDetailLink = t('routes.productDetailPage.Default', { sku });

    if (fundraiserType === FundraiserType.MealDeal) {
      productDetailLink = supportGuid
        ? t(`routes.productDetailPage.${ fundraiserType }`, { sku, supportGuid })
        : t(`routes.productDetailPageNoSupportGuid.${ fundraiserType }`, { sku });
    }

    return productDetailLink;
  }, [ sku, supportGuid, fundraiserType, t ]);
};
