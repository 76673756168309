import usaSocceLogo from '../assets/usa-soccer.png';
import coerverLogo from '../assets/coerver.png';
import soccerSand from '../assets/soccer-in-the-sand.png';
import msysa from '../assets/msysa.png';
import jaguars from '../assets/jaguars.jpg';
import boulder from '../assets/boulder.jpg';
import psc from '../assets/psc.jpeg';
import albionsc from '../assets/albionsc.png';
import lcahl from '../assets/lcahl-logo.png';
import ccg from '../assets/CCG.png';
import michStars from '../assets/MichStars.png';
import dsc from '../assets/dsc-logo.png';
import casl from '../assets/casl.jpg';
import kick from '../assets/kick_logo.jpg';

export const ImageObj = {
  1: { logo: usaSocceLogo, alt: 'USA Soccer Logo' },
  2: { logo: coerverLogo, alt: 'Coerver Logo' },
  56: { logo: soccerSand, alt: 'Soccer in the Sand' },
  58: { logo: msysa, alt: 'Michigan State Youth Soccer Association' },
  59: { logo: jaguars, alt: 'Jaguars' },
  60: { logo: boulder, alt: 'Boulder' },
  61: { logo: psc, alt: 'Players Soccer Club' },
  63: { logo: albionsc, alt: 'Albion SC Boulder County' },
  64: { logo: lcahl, alt: 'Little Caesars Amateur Hockey League: Travel Teams' },
  65: { logo: lcahl, alt: 'Little Caesars Amateur Hockey League: House Teams' },
  66: { logo: ccg, alt: 'Copper Country Gymnastics' },
  67: { logo: michStars, alt: 'Michigan Stars Lightning' },
  68: { logo: dsc, alt: 'Dickinson Soccer Club' },
  69: { logo: casl, alt: 'CASL' },
  70: { logo: kick, alt: 'KICK' },
};

export const GetGroupImage = (id: string) => ImageObj[id];
