import * as React from 'react';
import { CheckSquare } from 'react-feather';
import { Flex, Text } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';

import { RouterLink } from '../RouterLink';

import './toast.css';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { GoogleEvents } from 'features/analytics';
import { useCartState } from 'features/cart';

export interface IToastProps {
  text: string;
  closeToast?: () => void;
}

const AddToCartToast: React.FC<IToastProps> = ({ text }) => {
  const [ t ] = useTranslation();
  const { fundraiserType } = useFundraiserState();
  const { cart, subtotal } = useCartState();

  const cartRoute = t('routes.cart', { context: fundraiserType });

  const gaViewCartOnClick = () => {
    GoogleEvents.viewCart({
      cartTotal: subtotal,
      products: _map(cart.items, item => ({
        name: item.ProductName,
        price: item.UnitPrice,
        id: item.ProductId.toString(),
        quantity: item.Quantity,
      })),
    });
  };

  return (
    <Flex
      sx={ {
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
      } }
    >
      <CheckSquare color="#FF671B" size={ 35 } />
      <Text variant="text.toast.content">
        {text}
      </Text>
      <RouterLink
        onClick={ gaViewCartOnClick }
        to={ cartRoute }
        variant="links.toast"
      >
        {t('cart.ViewCart')}
      </RouterLink>
    </Flex>
  );
};

const GenericToast: React.FC<IToastProps> = ({ text }) => (
  <Flex
    sx={ {
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px',
    } }
  >
    <Text sx={ { textAlign: 'center', flex: 1 } } variant="text.toast.content">
      {text}
    </Text>
  </Flex>
);

export { AddToCartToast, GenericToast };
