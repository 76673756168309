import React from 'react';
import { Text, Flex } from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import _isNil from 'lodash/isNil';
import { useFeature } from '@growthbook/growthbook-react';

import { FeaturedFundraiserTracker } from './FeaturedFundraiserTracker';

const FeaturedFundraiser: React.FC = () => {
  const [ t ] = useTranslation();
  const featuredFundraiser = useFeature('fr_featured_fundraiser').value;

  if (_isNil(featuredFundraiser)) {
    return null;
  }
  const { seller } = featuredFundraiser;
  if (_isNil(seller)) {
    return null;
  }
  return (
    <Flex
      data-testid="featured-fundraiser"
      sx={ {
        alignItems: 'center',
        flexDirection: 'column',
        gap: '16px',
        p: [ '32px 16px', '32px' ],
        textAlign: 'center',
        width: '100%',
      } }
    >
      <Text variant="home.subheading">
        {t('home.featuredFundraiser.title')}
      </Text>
      <FeaturedFundraiserTracker { ...featuredFundraiser } />
    </Flex>
  );
};

export default FeaturedFundraiser;
