/* eslint-disable max-len */
import * as React from 'react';
import {
  Box, Button, Flex, Text,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';

import { Step, Mode } from 'ui/common';
import { useCheckoutState } from 'features/checkout';
import { CartListItem } from 'ui/cart';
import { InlineLinkParseAndReplace } from 'ui/components';

export interface IOrderReviewProps {
  mode: Mode;
  onEdit: () => void;
  onPlaceOrder: () => void;
}

// eslint-disable-next-line max-lines-per-function
export const OrderReviewMD: React.FC<IOrderReviewProps> = ({
  mode, onEdit, onPlaceOrder,
}) => {
  const [ t ] = useTranslation();

  const checkout = useCheckoutState();

  return (
    <Step
      editText={ t('checkout.EditCart') }
      onEdit={ onEdit }
      showEdit={ mode === 'active' }
      title={ t('checkout.OrderReview.Label') }
    >
      <Box data-testid="order-review-inner" sx={ { display: mode === 'active' ? 'block' : 'none' } } variant="cards.step.body">
        <Flex sx={ { flexDirection: 'column' } }>
          { checkout && checkout.checkout &&
            checkout.checkout.Cart.map(item => (
              <CartListItem
                cartItem={ item }
                disableChanges={ true }
                key={ item.ProductId }
                variant="cartListItem.checkout"
              />
            ))}
          <Button
            data-testid="place-order-button"
            disabled={ checkout.loading }
            onClick={ onPlaceOrder }
            variant={ checkout.loading ? 'disabled' : 'primary' }
          >
            { t('checkout.PlaceOrder') }
          </Button>
          <Text sx={ { mt: '10px' } } variant="legalDisclaimer">
            <InlineLinkParseAndReplace
              rel="noopener noreferrer"
              target="_blank"
              text={ t('checkout.OrderReview.Legal') }
              wordsToLink={ t('checkout.OrderReview.LegalLinks', { returnObjects: true }) }
            />
          </Text>
        </Flex>
      </Box>
    </Step>
  );
};
