/* eslint-disable no-extra-parens */
/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { RouteProps, Redirect, useLocation } from 'react-router-dom';
import {
  Box, Container, Heading, Spinner, Text,
} from '@lce/slice_v2';

import { useFundraiserDispatch } from 'features/fundraiser/context/fundraiserContext';
import { ProductList } from 'ui/products/ProductList';
import { FundraiserTracker } from 'ui/fundraiser/FundraiserTracker';
import { NotRunningFundraiserModalsDisplay } from 'ui/fundraiser/NotRunningFundraisersModals';
import { useCartState } from 'features/cart';
import { useSupportFundraiser } from 'features/fundraiser';
import { ProductCategorySelector } from 'ui/products';
import { useStatefulFetchSellerBySupportGuid } from 'ui/hooks';

type ProductsPageProps = RouteProps;

const Products: React.FC<ProductsPageProps> = () => {
  const cartState = useCartState();
  const { supportSeller, startShopping } = useSupportFundraiser();

  const { data: seller, isLoading, error } = useStatefulFetchSellerBySupportGuid();
  const isFranchiseeRef = seller?.IsFundraiserReferredByFranchisee || false;
  const isValidFundraiser = seller && seller.CanSupport && seller.Active && !seller.IsDeleted;

  const defaultCategories = {
    production: '5',
    qa: '7',
    development: '4',
    local: '4',
    jest: '4',
  };

  const defaultCategory = defaultCategories[process.env.REACT_APP_EFFECTIVE_ENV || ''];

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const activeCategory = parseInt(urlSearchParams.get('c') || defaultCategory);

  useEffect(() => {
    if (seller && seller.CanSupport) {
      supportSeller(seller.SupportGuid);
      startShopping();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ seller, useFundraiserDispatch ]);

  // no seller found - 404
  if (seller && seller.CustomerId === 0) {
    return <Redirect to="/404" />;
  }

  // loading state
  if (isLoading && !error) {
    return (
      <Container sx={ { textAlign: 'center' } }>
        <Spinner variant="lce" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Heading>Uh oh</Heading>
        <Text variant="text.header.sub">There was an issue getting seller information</Text>
      </Container>
    );
  }

  return (
    <div>
      <Box sx={ { pt: [ 0, '30px', '30px', 0 ] } }>
        {seller && (isValidFundraiser
          ? <FundraiserTracker />
          : <NotRunningFundraiserModalsDisplay seller={ seller } />)}
        {!isFranchiseeRef &&
          <ProductCategorySelector />}
        <ProductList
          canStartShopping={ cartState.canStartShopping }
          currentCategory={ activeCategory }
        >
          Add To Cart
        </ProductList>
      </Box>
    </div>
  );
};

export default Products;
