export default {
  productCardImageBR: {
    backgroundColor: 'transparent',
    objectFit: [ 'contain', 'cover' ],
    width: '100%',
    height: 'auto',
    maxHeight: '228px',
    minWidth: '100px',
    minHeight: '100px',
  },
  productCardImageDG: {
    backgroundColor: 'transparent',
    objectFit: [ 'contain', 'cover' ],
    width: '100%',
    height: 'auto',
    maxHeight: '228px',
    minWidth: '100px',
    minHeight: '100px',
  },
  productCardImageMD: {
    backgroundColor: [ 'primaryOrange', 'transparent' ],
    objectFit: [ 'contain', 'cover' ],
    width: '100%',
    height: 'auto',
    maxWidth: [ '100px', 'none' ],
    maxHeight: [ '100px', '228px' ],
    minWidth: '100px',
    minHeight: '100px',
  },
};
