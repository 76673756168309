import { useQuery } from 'react-query';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { noop as _noop } from 'lodash';

import { fetchSellerBySupportGuid } from '../../api';
import { Seller } from '../../types';

import * as QueryKeys from 'constants/QueryKeys';
import { ApiError } from 'features/common';
import { useCartState } from 'features/cart';

const useFetchSellerBySupportGuid = (onSuccess: (seller: Seller) => void = _noop) => {
  const cartState = useCartState();
  const { params } = useRouteMatch();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const supportGuid = urlSearchParams.get('support');

  const id = params['supportGuid'] || supportGuid || cartState.sellerId || '';

  return useQuery<Seller, ApiError>(`${ QueryKeys.SellerPrefix }-${ id }`,
    () => fetchSellerBySupportGuid(id),
    {
      enabled: !!id,
      onSuccess,
      retry: false,
      refetchOnWindowFocus: false,
    });
};

export { useFetchSellerBySupportGuid };
