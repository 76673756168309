/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React, {
  useState, useEffect, useCallback, ReactElement,
} from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';
import _every from 'lodash/every';
import _find from 'lodash/find';

import { processOrder } from 'features/checkout/utils/processOrder';
import { clearCart, useCartState, useCartDispatch } from 'features/cart';
import {
  useCheckoutRequest,
  useCheckoutDispatch,
  usePlaceOrder,
  useCheckoutState,
  PlaceOrderRequest,
  usePaymentState,
  usePaymentDispatch,
} from 'features/checkout';
import { GoogleEvents } from 'features/analytics';
import { calculateSubtotal } from 'features/cart/context/cartContext/cartReducer';
import { CustomerData } from 'features/cart/context/cartContext/cartContext';
import { useLocalStorage } from 'features/common';
import { appInsights } from 'features/analytics/ApplicationInsights';
import { generateID } from 'features/checkout/utils/generateID';
import { callEprotect } from 'features/checkout/utils/eProtect';
import { useFundraiserState } from 'features/fundraiser/context/fundraiserContext';
import { ICheckoutWrapperChildrenProps } from 'ui/checkout';
import { updateCart } from 'features/cart/context/cartContext/cartActions';
import { areAllFreeShippingItemsDonationItems } from 'features/fundraiser/utils';

export interface ICheckoutProcessProps {
  children: (props: ICheckoutWrapperChildrenProps) => ReactElement;
  isGuest: boolean;
}

// eslint-disable-next-line max-lines-per-function
const CheckoutProcess: React.FC <ICheckoutProcessProps> = ({ children, isGuest }) => {
  const [ t ] = useTranslation();
  const [ details, setDetails ] = useState<boolean>(false);
  const [ checkoutStarted, setCheckoutStarted ] = useState<boolean>(false);
  const [ canCheckout, setCanCheckout ] = useState<boolean>(true);
  const { fundraiserType } = useFundraiserState();

  const history = useHistory();

  const {
    mutateAsync: checkout, error: checkoutError,
  } = useCheckoutRequest();

  const { mutateAsync: createOrder } = usePlaceOrder();
  const cartState = useCartState();
  const cartDispatch = useCartDispatch();
  const checkoutState = useCheckoutState();
  const checkoutDispatch = useCheckoutDispatch();
  const paymentState = usePaymentState();
  const paymentDispatch = usePaymentDispatch();
  const [ , setCustomerData ] = useLocalStorage<CustomerData>('fundraiser/buyer');
  const ExpireDate = paymentState.card.expiryYear + paymentState.card.expiryMonth;

  const hasOnlyCouponItems = _every(checkoutState.checkout?.Cart, item => item.IsFreeShipping === true);
  const hasOnlyDonationItems = areAllFreeShippingItemsDonationItems(checkoutState.checkout?.Cart);
  const isCouponItemExist = _find(checkoutState.checkout?.Cart, item => item.IsFreeShipping === true)?.IsFreeShipping;

  const orderId = generateID();
  const merchantTxnId = generateID();
  const eProtectRequest = {
    paypageId: process.env.REACT_APP_EPROTECT_PAYPAGEID,
    reportGroup: 'FR_REPORTGROUP',
    orderId: orderId,
    id: merchantTxnId,
    url: process.env.REACT_APP_EPROTECT_URL,
  };
  const formFields = {
    accountNum: document.getElementById('ccNum'),
    cvv: document.getElementById('cvvNum'),
    paypageRegistrationId: document.getElementById('paypageRegistrationId'),
    bin: document.getElementById('bin'),
  };

  const redirectToCart = () => {
    history.push(t('routes.cart', { context: fundraiserType }));
  };

  const startCheckout = useCallback(async() => {
    setCheckoutStarted(true);

    if (!cartState.sellerId) {
      setCanCheckout(false);
      history.push(`${ t('routes.cart', { context: fundraiserType }) }?error=nofundraiser`);
      return;
    }

    const data = await checkout({
      isGuest: isGuest,
      cart: cartState.cart.items,
      cartSubTotal: cartState.subtotal,
      sellerSupportId: cartState.sellerId,
      guestId: isGuest ? cartState.guestId || '' : '',
      giftMessageRecipient: '',
      giftMessage: '',
      tokenId: paymentState.token,
      Cvc: paymentState.card.cvvNum,
      ExpirationDate: ExpireDate,
    });

    if (!data || !data.CheckoutEnabled) {
      setCanCheckout(false);
      return;
    }

    if (data.HasWarnings) {
      updateCart(cartDispatch, data.Cart);
      history.push(t('routes.cart', { context: fundraiserType }));
      return;
    }

    checkoutDispatch({ type: 'SET_CHECKOUT', checkout: data });

    cartDispatch({ type: 'SET_GUEST', guestId: data.GuestId || undefined });
  }, [
    cartDispatch,
    cartState,
    checkout,
    checkoutDispatch,
    fundraiserType,
    isGuest,
    history,
    ExpireDate,
    paymentState.card.cvvNum,
    paymentState.token,
    t,
  ]);

  const orderComplete = useCallback((checkoutState) => {
    clearCart(cartDispatch);
    setCustomerData({});
    if (isGuest) {
      appInsights.clearAuthenticatedUserContext();
    }

    GoogleEvents.purchase({
      subtotal: checkoutState.checkout?.Subtotal || 0,
      purchaseId: checkoutState.orderId,
      shippingTotal: checkoutState.checkout?.ShippingTotal || 0,
      products: _map(checkoutState.checkout?.Cart, item => ({
        name: item.ProductName,
        price: item.UnitPrice,
        id: item.ProductId.toString(),
        quantity: item.Quantity,
      })),
    });

    history.push(t('checkout.routes.orderConfirmation', { context: fundraiserType, orderGuid: checkoutState.orderGuid }),
      {
        isGuest: isGuest,
        guestId: checkoutState.checkout?.GuestId,
        guestAddress: checkoutState.shippingAddress,
      });
  }, [
    cartDispatch,
    isGuest,
    history,
    fundraiserType,
    setCustomerData,
    t,
  ]);

  useEffect(() => {
    if (!checkoutStarted) {
      startCheckout();
    }
  }, [ startCheckout, checkoutStarted ]);

  useEffect(() => {
    const handleUnload = (e) => {
      e.preventDefault();
      const message = t('checkout.OrderProcessingWarning');
      e.returnValue = message;
      return e;
    };

    window.onbeforeunload = checkoutState.loading ? handleUnload : null;
  }, [ checkoutState.loading, t ]);

  useEffect(() => {
    if (!checkoutState.loading && checkoutState.paymentStatus === 'success' && checkoutState.orderGuid) {
      orderComplete(checkoutState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ checkoutState ]);

  useEffect(() => {
    // eslint-disable-next-line no-extra-parens
    if ((paymentState.inlineError && !paymentState.popup) || paymentState.showPaymentStep) {
      document.getElementById('paymentAnchor')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    // eslint-disable-next-line no-extra-parens
    if (paymentState.showReviewStep) {
      document.getElementById('submitAnchor')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [ paymentState.inlineError, paymentState.popup, paymentState.showPaymentStep, paymentState.showReviewStep ]);

  const placeOrderHandler = async() => {
    checkoutDispatch({ type: 'SET_LOADING' });
    paymentDispatch({ type: 'UNSET_REVIEW_STEP' });
    // eslint-disable-next-line no-extra-parens
    if (checkoutState.paymentStatus === 'retry' || (checkoutState.paymentStatus === 'fail' && !paymentState.token)) {
      callEprotect(eProtectRequest, formFields, paymentDispatch, checkoutDispatch, 'retry');
    }
    if (!checkoutState.checkout) {
      return;
    }
    if (!paymentState.token) {
      return;
    }

    const orderRequest: PlaceOrderRequest = {
      cart: checkoutState.checkout.Cart,
      cartSubTotal: checkoutState.checkout.Subtotal,
      total: checkoutState.checkout.OrderTotal,
      sellerId: checkoutState.checkout.SellerId,
      guestId: checkoutState.checkout.GuestId,
      shippingAddress: checkoutState.shippingAddress,
      billingAddress: checkoutState.billingAddress,
      isGuest: checkoutState.checkout.IsGuest,
      giftMessageRecipient: checkoutState.giftMessageRecipient,
      giftMessage: checkoutState.giftMessage,
      tokenId: paymentState.token,
      Cvc: paymentState.card.cvvNum,
      ExpirationDate: ExpireDate,
      eProtectOrderId: orderId,
      eProtectMerchantTxnId: merchantTxnId,
    };

    if (checkoutState.orderId) {
      orderRequest.orderId = checkoutState.orderId;
    }

    // always call createOrder so that the order is always up to date w/ the client
    const order = await createOrder(orderRequest);

    const hasWarnings = _find(order?.Cart, item => item.Warnings?.length > 0);

    if (hasWarnings && order) {
      checkoutDispatch({ type: 'UNSET_LOADING' });
      const CheckoutInfo = {
        ...checkoutState.checkout,
        Subtotal: calculateSubtotal({ items: order.Cart }),
        Cart: order.Cart,
      };
      checkoutDispatch({ type: 'SET_CHECKOUT', checkout: CheckoutInfo });
      updateCart(cartDispatch, order.Cart);
      return redirectToCart();
    }

    processOrder(order, checkoutDispatch, paymentDispatch);
  };

  const handleModalClose = () => {
    document.getElementById('submitAnchor')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    paymentDispatch({ type: 'UNSET_INLINE_ERROR' });
    paymentDispatch({ type: 'UNSET_POPUP' });
  };

  const handlePaymentInfoComplete = () => {
    if (paymentState.code) {
      checkoutDispatch({ type: 'SET_PAYMENT_STATUS', status: 'pending' });
    }
    paymentDispatch({ type: 'UNSET_INLINE_ERROR' });
    paymentDispatch({ type: 'UNSET_PAYMENT_STEP' });
    callEprotect(eProtectRequest, formFields, paymentDispatch, checkoutDispatch, 'pending');
  };

  if (checkoutError || !canCheckout) {
    return <Redirect to={ `${ t('routes.cart', { context: fundraiserType }) }?error=nocheckout` } />;
  }

  return children({
    checkoutState,
    details,
    setDetails,
    paymentState,
    paymentDispatch,
    hasOnlyCouponItems,
    hasOnlyDonationItems,
    isCouponItemExist,
    handleModalClose,
    handlePaymentInfoComplete,
    redirectToCart,
    placeOrderHandler,
  });
};

export default CheckoutProcess;
