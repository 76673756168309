/* eslint-disable max-lines-per-function */
import {
  Flex, Form, Button, Box, Spinner, Text,
} from '@lce/slice_v2';
import React from 'react';
import { useTranslation } from '@lce/i18n';
import _map from 'lodash/map';

import { IShippingDetails } from './types';
import { useShippingSchema } from './hooks';
import { ShippingDetailsMDLoader as ShippingDetailsBRLoader } from './ShippingDetailsMDLoader';

import { useCheckoutState } from 'features/checkout';
import { FirstNameInput, PhoneInput } from 'ui/components';
import { GoogleEvents } from 'features/analytics';

export const ShippingDetailsBR: React.FC<IShippingDetails> = ({ setShippingDetails, shippingDetails, loading }) => {
  const [ t ] = useTranslation();
  const { checkout } = useCheckoutState();

  const hasOnlyCouponItems = checkout?.Cart.every(cart => cart.IsFreeShipping === true);

  const validationSchema = useShippingSchema(true);

  const handleOnSubmit = (e) => {
    setShippingDetails(e, false);
  };

  const gaAddShippingInfoOnSubmit = (subtotal, cart) => {
    GoogleEvents.addShippingInfo({
      cartTotal: subtotal,
      products: _map(cart, item => ({
        name: item.ProductName,
        price: item.UnitPrice,
        id: item.ProductId.toString(),
        quantity: item.Quantity,
      })),
    });
  };

  return (
    <Box data-testid="shipping-details-form">
      {checkout ? (
        <Form
          initialValues={ shippingDetails }
          onSubmit={ (data) => {
            gaAddShippingInfoOnSubmit(checkout.Subtotal, checkout.Cart);
            handleOnSubmit(data);
          } }
          reValidateMode="onSubmit"
          summary={ false }
          validationSchema={ validationSchema }
        >
          <Flex
            sx={ {
              flexDirection: 'column',
            } }
          >
            <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
              <FirstNameInput
                label={ t('checkout.ShippingDetails.firstNameLabel') }
                sx={ { my: 0 } }
                testId="shipping_firstName"
                variant="forms.checkout.field"
              />

              <Form.Field
                defaultValue={ hasOnlyCouponItems }
                id="IsCouponItem"
                label="IsCouponItem"
                name="IsCouponItem"
                sx={ { display: 'none' } }
              />

              <Form.Field
                data-testid="shipping_lastName"
                id="LastName"
                label={ t('checkout.ShippingDetails.lastNameLabel') }
                name="LastName"
                sx={ { marginLeft: [ 0, '12px' ], my: 0 } }
                variant="forms.checkout.field"
              />
            </Flex>

            <Text sx={ { mt: '30px' } } variant="text.header.form">
              {t('checkout.OrderDetailLabel', { context: 'BR' })}
            </Text>

            <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
              <Form.Field
                data-testid="shipping_emailAddress"
                id="EmailAddress"
                label={ t('checkout.ShippingDetails.emailAddressLabel') }
                name="Email"
                onCopy={ (e) => {
                  e.preventDefault();
                  return false;
                } } onPaste={ (e) => {
                  e.preventDefault();
                  return false;
                } }
                sx={ { mr: [ 0, '24px' ] } }
                variant="forms.checkout.field"
              />
              <Form.Field
                data-testid="shipping_confirmEmailAddress"
                id="Confirm-Email"
                label={ t('checkout.ShippingDetails.confirmEmailAddressLabel') }
                name="ConfirmEmailAddress"
                onCopy={ (e) => {
                  e.preventDefault();
                  return false;
                } }
                onPaste={ (e) => {
                  e.preventDefault();
                  return false;
                } }
                sx={ { mr: 0 } }
                variant="forms.checkout.field"
              />
            </Flex>

            <PhoneInput
              defaultValue={ shippingDetails?.PhoneNumber }
              label={ t('checkout.ShippingDetails.phoneNumberLabel') }
              sx={ { width: [ '100%', '48%' ] } }
              testId="shipping_phoneNumber"
            />

            <Button
              data-testid="shipping_button"
              disabled={ loading ? true : false }
              id="shippingNext"
              sx={ { mt: '33px' } }
              type="submit"
              variant={ loading ? 'disabled' : 'primary' }
            >
              { loading ? (
                <Spinner sx={ { height: '100%' } } variant="lce" />
              ) : t('checkout.NextBillingLabel') }
            </Button>
          </Flex>
        </Form>
      ) : <ShippingDetailsBRLoader />}
    </Box>
  );
};
