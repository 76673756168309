import { forEach as _forEach } from 'lodash';
import toLower from 'lodash/toLower';

import { groups } from '../utils/GroupsData';

const useFetchValidParentGroup = (id: string, name: string) => {
  let valid = false;
  _forEach(groups, (group) => {
    if (id === group.id && toLower(name) === toLower(group.name)) {
      valid = true;
    }
  });
  return valid;
};

export { useFetchValidParentGroup };
