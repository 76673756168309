/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect } from 'react';
import { Text, Box } from '@lce/slice_v2';
import _map from 'lodash/map';
import _find from 'lodash/find';
import { Redirect } from 'react-router-dom';

import { ProductListItem, ProductListItemSkeleton } from '../ProductListItem';
import { filterProductList } from '../utils/filterProductList';

import * as QueryKeys from 'constants/QueryKeys';
import { useCartDispatch, addToCart } from 'features/cart/context/cartContext';
import { ProductCategory, useFetchProductsByFundraiserType } from 'features/products';
import { GoogleEvents } from 'features/analytics';
import { queryClient } from 'query-client';

export interface IProductList {
  canStartShopping: boolean;
  supportGuid?: string;
  currentCategory?: number;
}

const ProductList: React.FC<IProductList> = ({
  canStartShopping, supportGuid, currentCategory, children,
}) => {
  const {
    data: productResult, isLoading: isProductResultLoading, isError: isProductResultError,
  } = useFetchProductsByFundraiserType();

  const filteredProducts = productResult && currentCategory && filterProductList(productResult, currentCategory);

  useEffect(() => {
    const productCategories = queryClient.getQueryState<ProductCategory[]>(QueryKeys.ProductCategories);
    if (productCategories && productCategories.data) {
      const categoryName = _find(productCategories.data, (c: ProductCategory) => c.Id === currentCategory)?.Name;
      if (categoryName && currentCategory && filteredProducts) {
        GoogleEvents.viewItemList({
          categoryName,
          categoryId: currentCategory.toString(),
          products: _map(filteredProducts, item => ({
            name: item.Name,
            id: item.Id.toString(),
            price: item.Price,
          })),
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ currentCategory ]);

  const cartDispatch = useCartDispatch();
  const addToCartHandler = useCallback((product, quantity) => {
    addToCart(cartDispatch, product, quantity);
  }, [ cartDispatch ]);

  if (isProductResultError) {
    return <Redirect to="/buyer" />;
  }

  if (isProductResultLoading) {
    return (
      <Box data-testid="product-list-skeleton" variant="cards.productCardList">
        <ProductListItemSkeleton />
        <ProductListItemSkeleton />
        <ProductListItemSkeleton />
      </Box>
    );
  }

  if (!filteredProducts || filteredProducts.length <= 0) {
    return (
      <Box sx={ { textAlign: 'center', width: '100%', marginTop: '15px' } }>
        <Text>No products found.</Text>
      </Box>
    );
  }

  return (
    <Box variant="cards.productCardList">
      {filteredProducts.map(product => (
        <ProductListItem
          canStartShopping={ canStartShopping }
          key={ product.Id.toString() }
          onAddToCart={ addToCartHandler }
          product={ product }
          supportGuid={ supportGuid }
        >
          {children}
        </ProductListItem>
      ))}
    </Box>
  );
};

export default ProductList;
