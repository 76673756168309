import { FundraiserType } from 'features/fundraiser/types';

// eslint-disable-next-line max-lines-per-function
export const fundraiserReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FUNDRAISER_ID':
      return {
        ...state,
        fundraiserId: action.fundraiserId,
      };
    case 'UNSET_FUNDRAISER_ID':
      return {
        ...state,
        fundraiserId: undefined,
      };
    case 'SET_FUNDRAISER_TYPE':
      if (action.fundraiserType === FundraiserType.SimpleSignUp) {
        return {
          ...state,
          fundraiserType: FundraiserType.Digital,
        };
      }

      return {
        ...state,
        fundraiserType: action.fundraiserType,
      };
    case 'UNSET_FUNDRAISER_TYPE':
      return {
        ...state,
        fundraiserType: FundraiserType.Digital,
      };
    case 'SET_ACTIVE':
      return {
        ...state,
        active: action.active,
      };
    case 'UNSET_ACTIVE':
      return {
        ...state,
        active: 0,
      };
    default:
      return state;
  }
};
