import React from 'react';
import {
  Box, Button, Flex, Heading, Image, Text, Spinner,
  Link,
} from '@lce/slice_v2';
import { match, useHistory } from 'react-router-dom';
import { useTranslation } from '@lce/i18n';

import { FinalOrderStatus } from 'features/fundraiser/types/fundraiser';
import anouncerCaesarMan from 'assets/images/Announcer-Caesar-Man.png';
import { useStatefulFetchFundraiserById, useFundraiserFinalOrderStatus } from 'ui/hooks';
import { FundraiserNavigation } from 'ui/fundraiser/FundraiserNavigation';
import { InlineLinkParseAndReplace } from 'ui/components';

/* eslint-disable max-lines-per-function */
interface MatchParams {
  fundraiserId: string;
}

export type FundraiserConfirmationProps = {
  match: match<MatchParams>;
}

export const dataTestIdFinalOrderConfirmation = 'final-order-confirmation';

// eslint-disable-next-line max-lines-per-function
export const FundraiserConfirmation: React.FC<FundraiserConfirmationProps> = ({ match }) => {
  const fundraiserId = match?.params?.fundraiserId;

  const {
    data: fundraiser,
    isLoading: isFundraiserDataLoading,
    // isError: fundraiserFetchError,
  } = useStatefulFetchFundraiserById(fundraiserId);

  const finalOrderState = useFundraiserFinalOrderStatus(fundraiser);

  if (isFundraiserDataLoading) {
    return (
      <Flex
        data-testid={ `${ dataTestIdFinalOrderConfirmation }-loading` }
        sx={ {
          alignItems: 'center', height: '100vh', justifyContent: 'center', width: '100%',
        } }
      >
        <Spinner variant="lce" />
      </Flex>
    );
  }

  return (
    <>
      <FundraiserNavigation fundId={ fundraiserId } url={ match.url } />
      <FinalOrderConfirmation
        disableEditFormButton={ finalOrderState > FinalOrderStatus.FinalOrderSubmitted }
        finalOrderStatus={ finalOrderState }
        fundraiserId={ fundraiserId }
      />
    </>
  );
};

export type FinalOrderConfirmationProps ={
  fundraiserId: string;
  disableEditFormButton: boolean;
  finalOrderStatus: FinalOrderStatus;
}

export const FinalOrderConfirmation: React.FC<FinalOrderConfirmationProps> = ({
  fundraiserId, disableEditFormButton, finalOrderStatus,
}) => {
  const [ t ] = useTranslation();
  const history = useHistory();

  const defaultEditButtonRoute = {
    pathname: `/dashboard/fundraisers/final-order/${ fundraiserId }`,
  };
  const finalOrderSubmittedEditButtonRoute = {
    ...defaultEditButtonRoute,
    state: { finalOrderSubmitted: true },
  };
  const editButtonRoute = finalOrderStatus === FinalOrderStatus.FinalOrderSubmitted
    ? finalOrderSubmittedEditButtonRoute
    : defaultEditButtonRoute;

  return (
    <Flex
      data-testid={ dataTestIdFinalOrderConfirmation }
      sx={ {
        gap: '16px', marginTop: [ '32px', '64px' ], flexDirection: 'column', alignItems: 'center',
      } }
    >
      <Image alt="Caesar Man Announcer" src={ anouncerCaesarMan } sx={ { width: [ '157px', '217px' ] } } />
      <Heading as="h1">
        {t('dashboard.finalOrderPage.confirmation.header')}
      </Heading>
      {!disableEditFormButton && (
        <Text sx={ { textAlign: 'center' } }>
          {t('dashboard.finalOrderPage.confirmation.content')}
        </Text>
      )}

      <Box
        sx={ {
          display: 'grid',
          gridTemplateColumns: [ '1fr', '1fr 1fr' ],
          width: '100%',
          maxWidth: [ '248px', '512px' ],
          gap: '16px',
        } }
      >
        <Link
          data-testId={ `${ dataTestIdFinalOrderConfirmation }-print-button` }
          href={ `/dashboard/fundraisers/view-and-print/${ fundraiserId }` }
          variant="primaryButtonLink"
        >
          {t('dashboard.finalOrderPage.confirmation.print')}
        </Link>
        <Button
          data-testId={ `${ dataTestIdFinalOrderConfirmation }-edit-button` }
          disabled={ disableEditFormButton }
          onClick={ () => history.push(editButtonRoute) }
          variant={ disableEditFormButton ? 'disabled' : 'secondary' }
        >
          {t('dashboard.finalOrderPage.confirmation.edit')}
        </Button>
      </Box>
      {disableEditFormButton && (
        <Text sx={ { textAlign: 'center' } } variant="dashboard.finalOrderPage.error">
          <InlineLinkParseAndReplace text={ t('dashboard.finalOrderPage.confirmation.pastDueDate') } />
        </Text>
      )}
    </Flex>
  );
};
