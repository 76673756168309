/* eslint-disable max-len */
/* eslint-disable complexity */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@lce/i18n';
import {
  Button, Box, Form, Flex, Text, Container, Spinner, Option,
} from '@lce/slice_v2';
import * as Yup from 'yup';
import { useOktaAuth } from '@okta/okta-react';
import { subDays, format as dateformat } from 'date-fns';
import _ from 'lodash';

import { DeliveryInfo } from '../DeliveryInfo';

import * as FundraiserDefaults from './../../../../constants/FundraiserDefaults';
import FundraiserDetailsSummary from './FundraiserDetailsSummary/FundraiserDetailsSummary';
import './styles.css';

import { Step, Mode } from 'ui/common';
import {
  useFetchUserGroups, FundraiserInfo, Group, FundraiserType,
} from 'features/fundraiser';
import { useFetchGroupTypes } from 'features/fundraiser/hooks';
import { useFetchStates, useUserInfo } from 'features/common/hooks';
import { useFetchGroup } from 'features/fundraiser/hooks/useFetchGroup';
import { useVerifySalesAgent } from 'features/salesAgent';
import { FundraiserProfitCheckAddress } from 'features/fundraiser/types/fundraiser';
import { PhoneNumberLinkWrapper } from 'ui/fundraiser/ChairpersonSteps/Components';
import fonts from 'theme/components/fonts';

export interface IFundraiserDetailsProps {
  onEdit: () => void;
  onNext: (info: FundraiserInfo) => void;
  info: FundraiserInfo | undefined;
  mode: Mode;
  isEdit?: boolean;
  fundraiserType?: FundraiserType;
  profitCheckInfo?: FundraiserProfitCheckAddress;
  showEdit?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const FundraiserDetails: React.FC<IFundraiserDetailsProps> = ({
  onEdit, onNext, mode, info, isEdit, fundraiserType, profitCheckInfo, showEdit = true,
}) => {
  const [ t ] = useTranslation();
  const { authState } = useOktaAuth();
  const [ endDate, setEndDate ] = useState<string>(info?.EndDate ? dateformat(new Date(info.EndDate), 'yyyy-MM-dd') : '');
  const [ startDate, setStartDate ] = useState<string>(info?.StartDate ? dateformat(new Date(info.StartDate), 'yyyy-MM-dd') : '');
  const [ selectedGroupId, setSelectedGroupId ] = useState<string>(info?.GroupId.toString() || '0');
  const [ fundraiserInfo, setFundraiserInfo ] = useState<FundraiserInfo | undefined>(info);
  const [ userIsChairperson, setUserIsChairperson ] = useState<boolean>(false);
  const [ dollarGoal, setDollarGoal ] = useState<number>(info?.DollarGoal || 0);
  const [ numberOfSellers, setNumberOfSellers ] = useState<number>(info?.EstimatedSellers || 0);
  const [ calculatedKitGoal, setCalculatedKitGoal ] = useState<number>(info?.SellerKitGoal || 0);
  const [ existingGroup, setExistingGroup ] = useState<Group>();
  const [ canEditSalesCode, setCanEditSalesCode ] = useState<boolean>(false);
  const [ canEditDates, setCanEditDates ] = useState<boolean>(false);
  const [ canFetchSalesAgent, setCanFetchSalesAgent ] = useState<boolean>(false);
  const [ salesAgentHasErrors, setSalesAgentHasErrors ] = useState<boolean>(false);
  const [ salesCode, setSalesCode ] = useState<string>('');

  const isMealDealFundraiser = fundraiserType && fundraiserType === FundraiserType.MealDeal;
  const isBrochureFundraiser = fundraiserType && fundraiserType === FundraiserType.Brochure;

  const { data: groups, isLoading: isGroupLoading } = useFetchUserGroups();
  const { data: groupTypes, isLoading: isGroupTypeLoading } = useFetchGroupTypes();
  const { data: states } = useFetchStates(1);

  const { data: groupDetail } = useFetchGroup(selectedGroupId);
  const defaultGroupTypeId = groupDetail?.GroupTypeId;
  const defaultState = groupDetail?.GroupAddress?.StateProvinceIso ?? info?.GroupState;
  const {
    data: salesAgent, status: salesAgentStatus, isSuccess: isSuccessForSalesAgent, isLoading: isSalesAgentLoading,
  } =
    useVerifySalesAgent(canFetchSalesAgent, salesCode);

  const [ isGroupTypeIdDirty, setGroupTypeIdDirty ] = useState<boolean>(false);

  const hasOutOfRangeGroupTypeId = useMemo<boolean>(() => {
    if (isGroupTypeIdDirty) {
      return false;
    }

    if (Array.isArray(groupTypes)) {
      const highestGroupId = _.chain(groupTypes)
        .orderBy('Id')
        .last()
        .get('Id')
        .value();
      const currentGroupTypeId = groupDetail?.GroupTypeId ?? 0;

      return highestGroupId < currentGroupTypeId;
    }

    return false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ groupDetail?.GroupTypeId, groupTypes, isGroupTypeIdDirty ]);

  const { data: userInfo } = useUserInfo(authState.isAuthenticated);

  useEffect(() => {
    if (selectedGroupId === '0') {
      setExistingGroup(undefined);
    } else if (groupDetail) {
      setExistingGroup(groupDetail);
    }

    if (isEdit) {
      setFundraiserInfo(info);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ groupDetail, selectedGroupId ]);

  useEffect(() => {
    if (salesAgentStatus === 'success' || salesAgentStatus === 'error') {
      setCanFetchSalesAgent(false);

      if (salesAgent && !salesAgent.SalesCode) {
        setSalesAgentHasErrors(true);
      } else {
        setSalesAgentHasErrors(false);
      }
    }
  }, [ salesAgent, salesAgentStatus ]);

  useEffect(() => {
    if (userInfo && userInfo.Roles) {
      const { Roles } = userInfo;

      // ISC users cannot edit a sales code at any point during Edit.
      if (Roles.includes(FundraiserDefaults.ISCRoleName) && isEdit) {
        setCanEditSalesCode(false);
      }
      // ISC Users can edit sales code during creation
      if (Roles.includes(FundraiserDefaults.ISCRoleName) && !isEdit) {
        setCanEditSalesCode(true);
      }
      // Super admins can always edit sales code
      if (Roles.includes(FundraiserDefaults.SuperAdminRoleName)) {
        setCanEditSalesCode(true);
      }
      // Sales agent can only edit sales code when creating a fundraiser
      if (Roles.includes(FundraiserDefaults.SalesAgentRoleName) && !isEdit) {
        setCanEditSalesCode(true);
      }

      // Supers and Sales Agents can edit start and end dates
      if (Roles.includes(FundraiserDefaults.SuperAdminRoleName) ||
          Roles.includes(FundraiserDefaults.SalesAgentRoleName)) {
        setCanEditDates(true);
      }

      // Only get list of groups if one of these roles
      if (Roles.includes(FundraiserDefaults.SuperAdminRoleName) ||
          Roles.includes(FundraiserDefaults.ChairpersonRoleName) ||
          Roles.includes(FundraiserDefaults.SalesAgentRoleName)) {
        setUserIsChairperson(true);
      }
    }
  }, [ userInfo, isEdit ]);

  // eslint-disable-next-line max-lines-per-function
  const [ validationSchema, groupTypeIdLabel ] = useMemo(() => {
    const defaultSchemaMD = Yup.object({
      OrgName: Yup.string()
        .required('Organization Name is required')
        .matches(/^[a-zA-Z0-9\s']+$/, 'Fundraiser Organization Name field can accept ONLY letters, numbers, and apostrophe (for example: Jane Doe\'s School PTA)')
        .max(255, 'Organization Name is too long'),
      DollarGoal: Yup.number()
        .required('Profit Goal is required.')
        .nullable()
        .min(0.01, 'Profit Goal must be greater than 0')
        .max(1000000, 'Number too large')
        .typeError('Profit Goal must be a number.'),
      EstimatedSellers: Yup.number()
        .required('Estimated Number of sellers is required')
        .nullable()
        .integer('Estimated Number of sellers must be a whole number')
        .min(1, 'Estimated Number of sellers must be greater than 0')
        .max(99999, 'Number too large')
        .typeError('Number of sellers must a number'),
    });

    const defaultSchemaEdit = defaultSchemaMD.shape({
      GroupAddress1: Yup.string().required('Organization Address is required'),
      GroupCity: Yup.string().required('Organization City is required'),
      GroupState: Yup.string().required('Organization State is required'),
      GroupZip: Yup.string().required('Organization Zip is required'),
      FederalTaxId: Yup.string()
        .max(100, 'Tax ID must be under 100 characters'),
      TaxAcknowledgement: Yup.boolean()
        .required(t('fundraiser.TaxAcknowledgement', { context: isEdit ? 'isEdit' : '' }))
        .oneOf([ true ], t('fundraiser.TaxAcknowledgement', { context: isEdit ? 'isEdit' : '' })),
    });

    const today = new Date();
    const threeYearsFromToday = new Date();
    threeYearsFromToday.setFullYear(today.getFullYear() + 3);

    const defaultSchemaCreate = defaultSchemaEdit.shape({
      GroupId: Yup.number(),
      GroupTypeId: Yup.number().min(1, 'Must select a group type'),
      StartDate: Yup.date()
        .required('Start Date is required')
        .min(subDays(new Date(), 1), 'Select today or later')
        .max(threeYearsFromToday, 'Start date cannot be more than 3 years from today')
        .typeError('Start Date is required'),
    });

    const optionalGroupTypeIdLabel = 'Type of Organization';
    const requiredGroupTypeIdLabel = `${ optionalGroupTypeIdLabel } *`;

    if (isMealDealFundraiser) {
      return [ defaultSchemaMD, optionalGroupTypeIdLabel ];
    } else if (isEdit) {
      return [ defaultSchemaEdit, requiredGroupTypeIdLabel ];
    } else {
      return [ defaultSchemaCreate, requiredGroupTypeIdLabel ];
    }
  }, [ isEdit, t, isMealDealFundraiser ]);

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const startDate = new Date(e.target.value);

    if (startDate.getFullYear() >= 2020 && startDate.getFullYear() < 2100) {
      setStartDate(dateformat(new Date(`${ e.target.value }T00:00:00`), 'yyyy-MM-dd'));
      startDate.setDate(startDate.getDate() + 30);
      setEndDate(startDate.toISOString().slice(0, 10));
    }
  };

  const onGroupSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGroupId(e.currentTarget.value);
  };

  const calculateKitGoal = (sellers, dollarGoal) => {
    if (!sellers || !dollarGoal) {
      setCalculatedKitGoal(0);
      return;
    }

    const kits = dollarGoal / 6 / sellers;
    setCalculatedKitGoal(Math.ceil(kits));
  };

  const onDollarGoalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      return;
    }
    const goal = parseInt(e.target.value);
    setDollarGoal(goal);

    if (goal === 0 || numberOfSellers === 0) {
      setCalculatedKitGoal(0);
    }

    calculateKitGoal(numberOfSellers, goal);
  };

  const onEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && e.target.value > startDate) {
      setEndDate(dateformat(new Date(`${ e.target.value }T00:00:00`), 'yyyy-MM-dd'));
    }
  };

  const onNumberOfSellerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      return;
    }
    const sellers = parseInt(e.target.value);
    setNumberOfSellers(sellers);

    if (sellers === 0 || dollarGoal === 0) {
      setCalculatedKitGoal(0);
    }

    calculateKitGoal(sellers, dollarGoal);
  };

  const handleOnSubmit = (e) => {
    const { GroupTypeId, ...formData } = e;
    const GroupTypeName = _.find(groupTypes, gt => gt.Id === parseInt(e.GroupTypeId))?.Name;
    const info: FundraiserInfo = {
      ...formData,
      GroupTypeId: hasOutOfRangeGroupTypeId ? existingGroup?.GroupTypeId : parseInt(GroupTypeId),
      GroupAddressId: existingGroup?.GroupAddress?.Id || 0,
      GroupId: existingGroup?.Id || 0,
      GroupTypeName,
      SellerKitGoal: calculatedKitGoal,
      StartDate: startDate,
      EndDate: endDate,
      FundraiserType: fundraiserType || FundraiserType.Digital,
    };
    setFundraiserInfo(info);
    onNext(info);
  };

  if (isGroupLoading || isGroupTypeLoading) {
    return (
      <Step title={ t('fundraiser.YourFundraiser', { context: fundraiserType }) }>
        <Container sx={ { textAlign: 'center', pt: '30px', pb: '30px' } }>
          <Spinner variant="lce" />
        </Container>
      </Step>
    );
  }

  return (
    <>
      { mode === 'active' && (
        <Step onEdit={ onEdit } title={ t('fundraiser.YourFundraiser', { context: fundraiserType }) }>
          <Box data-testid="fundraiser-details-form" variant="cards.step.body">
            <Form
              initialValues={ info }
              onSubmit={ handleOnSubmit }
              summary={ false }
              validationSchema={ validationSchema }
            >
              <Text variant="text.header.form">
                {t('fundraiser.Organization')}
              </Text>
              <Flex sx={ { flexDirection: 'column' } }>
                {userIsChairperson && !isEdit && (
                  <Form.Select
                    defaultValue={ info?.GroupId }
                    id="GroupId"
                    label="Choose Group"
                    name="GroupId"
                    onChange={ e => onGroupSelect(e) }
                  >
                    <Option key="" label="New Group" value="0" />
                    {!_.isEmpty(groups) && _.map(groups, gt => (
                      <Option key={ gt.Id } label={ gt.Name } value={ gt.Id } />
                    ))}
                  </Form.Select>
                )}
                <Flex sx={ { flexDirection: 'column' } }>
                  {isEdit && !isMealDealFundraiser && (
                    <Form.Field
                      defaultValue={ groupDetail?.Name }
                      disabled={ true }
                      hint="Name to which profit checks will be paid. If you need to change this, please contact us at 888-452-5487."
                      id="FundName"
                      label={ t('fundraiser.Organization') }
                      name="FundName"
                      sx={ {
                        mb: 0,
                      } }
                      variant="forms.fundraiser.field.disabled"
                    />
                  ) }
                  <Form.Field
                    autoComplete="organization"
                    defaultValue={ existingGroup?.Name }
                    hint="Name displayed to buyers."
                    id="OrgName"
                    label="Fundraiser Organization Name * "
                    name="OrgName"
                    variant="forms.fundraiser.field"
                  />
                  <Form.Field
                    component={ Form.Select }
                    defaultValue={ defaultGroupTypeId || 0 }
                    id="GroupTypeId"
                    key={ defaultGroupTypeId }
                    label={ groupTypeIdLabel }
                    name="GroupTypeId"
                    onChange={ () => setGroupTypeIdDirty(true) }
                    sx={ { mt: '0px' } }
                    variant="forms.fundraiser.field"
                  >
                    <Option key="" label="None" value={ 0 } />
                    {groupTypes && _.map(groupTypes, gt => (
                      <Option
                        key={ gt.Id }
                        label={ gt.Name }
                        value={ gt.Id }
                      />
                    ))}
                  </Form.Field>
                </Flex>
                <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                  {isEdit
                    ? canEditDates
                      ? (
                        <Flex sx={ { flexDirection: 'column', flex: '1', marginTop: '16px' } }>
                          <Text>Start Date</Text>
                          <input
                            className="custom-date-field"
                            id="StartDate"
                            name="StartDate"
                            onChange={ handleStartDateChange }
                            style={ { marginRight: '10px' } }
                            type="date" value={ startDate }
                          />
                        </Flex>
                      )
                      : (
                        <Flex sx={ { flexDirection: 'column', flex: '1', margin: '16px 0px' } }>
                          <Text>
                            Start Date
                          </Text>
                          <Text sx={ { mt: '10px' } }>
                            { dateformat(new Date(info?.StartDate || 0), 'MM/dd/yyyy')}
                          </Text>
                        </Flex>
                      )
                    : (
                      <Form.Field
                        id="StartDate"
                        label="Start Date *"
                        name="StartDate"
                        onChange={ handleStartDateChange }
                        sx={ { marginRight: [ 0, '12px' ] } }
                        type="date"
                        variant="forms.fundraiser.field"
                      />
                    )}
                  {isEdit
                    ? canEditDates ? (
                      <Flex sx={ { flexDirection: 'column', flex: '1', marginTop: '16px' } }>
                        <Text sx={ { ml: '10px' } }>End Date</Text>
                        <input
                          className="custom-date-field"
                          id="EndDate"
                          name="EndDate"
                          onChange={ e => onEndDateChange(e) }
                          style={ { marginLeft: '10px' } }
                          type="date" value={ endDate }
                        />
                      </Flex>
                    ) : (
                      <Flex sx={ { flexDirection: 'column', flex: '1', margin: '16px 0px' } }>
                        <Text sx={ { ml: [ '0px', '20px' ] } }>
                          End Date
                        </Text>
                        <Text sx={ { mt: '10px', ml: [ '0px', '20px' ] } }>
                          { dateformat(new Date(info?.EndDate || 0), 'MM/dd/yyyy')}
                        </Text>
                      </Flex>
                    )
                    : (
                      <Form.Field
                        disabled={ true }
                        id="EndDate"
                        label="End Date"
                        name="EndDate"
                        sx={ { marginLeft: [ 0, '12px' ], color: 'primaryGrayDark' } }
                        type="date"
                        value={ endDate }
                        variant="forms.fundraiser.field"
                      />
                    )}
                </Flex>

                <Text variant="text.header.form">
                  {t('fundraiser.Goal')}
                </Text>

                <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                  <Form.Field
                    id="DollarGoal"
                    label="Profit Goal *"
                    max="1000000"
                    min="0"
                    name="DollarGoal"
                    onChange={ e => onDollarGoalChange(e) }
                    sx={ { marginRight: [ 0, '12px' ] } }
                    variant="forms.fundraiser.field"
                  />
                  <Form.Field
                    id="EstimatedSellers"
                    label="Number of Sellers *"
                    min="0"
                    name="EstimatedSellers"
                    onChange={ e => onNumberOfSellerChange(e) }
                    sx={ { marginLeft: [ 0, '12px' ], marginRight: [ 0, '12px' ] } }
                    variant="forms.fundraiser.field"
                  />
                  {isEdit ? (
                    <Flex sx={ { flexDirection: 'column', flex: '1', ml: '20px' } }>
                      <Text sx={ { mt: '15px' } }>
                        Seller Kit Goals
                      </Text>
                      <Text sx={ { mt: '10px' } }>
                        { calculatedKitGoal }
                      </Text>
                    </Flex>
                  ) : (
                    <Form.Field
                      disabled={ true }
                      id="SellerKitGoal"
                      label="Seller Kit Goals *"
                      min="0"
                      name="SellerKitGoal"
                      sx={ { marginLeft: [ 0, '12px' ], marginRight: [ 0, '12px' ] } }
                      type="number"
                      value={ calculatedKitGoal }
                      variant="forms.fundraiser.field"
                    />
                  )}
                </Flex>
                {!isMealDealFundraiser && (
                  <>
                    <Text variant="text.header.form">
                      {t('fundraiser.GroupMailingAddress')}
                    </Text>
                    <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                      <Form.Field
                        autoComplete="address-line1"
                        defaultValue={ existingGroup?.GroupAddress?.Address1 }
                        id="GroupAddress1"
                        label="Street Address *"
                        name="GroupAddress1"
                        sx={ { marginRight: [ 0, '12px' ] } }
                        variant="forms.fundraiser.field"
                      />
                      <Form.Field
                        autoComplete="address-line2"
                        defaultValue={ existingGroup?.GroupAddress?.Address2 }
                        id="GroupAddress2"
                        label="Apt/Suite/Other"
                        name="GroupAddress2"
                        sx={ { marginLeft: [ 0, '12px' ] } }
                        variant="forms.fundraiser.field"
                      />
                    </Flex>
                    <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                      <Form.Field
                        autoComplete="address-level2"
                        defaultValue={ existingGroup?.GroupAddress?.City }
                        id="GroupCity"
                        label="City *"
                        name="GroupCity"
                        sx={ { marginRight: [ 0, '12px' ] } }
                        variant="forms.fundraiser.field"
                      />
                      <Form.Field
                        autoComplete="address-level1"
                        component={ Form.Select }
                        defaultValue={ defaultState || '' }
                        id="GroupState"
                        key={ defaultState }
                        label="State *"
                        name="GroupState"
                        sx={ { marginLeft: [ 0, '12px' ] } }
                        variant="forms.fundraiser.field"
                      >
                        <Option value="">---</Option>
                        {states && _.map(states, state => (
                          <Option
                            key={ state.Id }
                            label={ state.Abbreviation }
                            value={ state.Abbreviation }
                          />
                        ))}
                      </Form.Field>
                      <Form.Field
                        autoComplete="postal-code"
                        defaultValue={ existingGroup?.GroupAddress?.ZipPostalCode }
                        id="GroupZip"
                        label="Zip/Postal Code *"
                        name="GroupZip"
                        sx={ { marginLeft: [ 0, '12px' ] } }
                        variant="forms.fundraiser.field"
                      />
                    </Flex>
                  </>
                )}
                { canEditSalesCode && (
                  <>
                    <Text variant="text.header.form">
                      {t('fundraiser.SalesAgent')}
                    </Text>
                    <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                      <Flex sx={ { flexDirection: 'column', width: '100%' } }>
                        <Form.Field
                          id="SalesAgentCode"
                          label="Sales Agent Code"
                          maxLength={ 15 }
                          name="SalesAgentCode"
                          onChange={ e => setSalesCode(e.target.value) }
                          sx={ { mr: '12px', width: '100%' } }
                        />
                        { salesAgentHasErrors && (
                          <Box color="red" sx={ { mt: '-10px' } }>
                            Unable to verify sales agent
                          </Box>
                        )}
                        { isSuccessForSalesAgent && salesAgent && salesAgent.SalesCode && (
                          <Box color="secondaryGreenDark" sx={ { mt: '-10px' } }>
                            Sales Code Applied
                          </Box>
                        )}
                      </Flex>
                      <Button
                        disabled={ isSalesAgentLoading }
                        onClick={ () => setCanFetchSalesAgent(true) }
                        sx={ {
                          ml: '12px', width: '100%',
                          mt: [ 0, '47px' ],
                        } }
                        type="button"
                        variant={ isSalesAgentLoading ? 'disabled' : 'primary' }
                      >
                        { isSalesAgentLoading ? (
                          <Spinner sx={ { height: '100%' } } variant="lce" />
                        ) : 'Apply' }
                      </Button>
                    </Flex>
                  </>
                )}
                {isBrochureFundraiser && info?.DeliveryInfo && <DeliveryInfo details={ info.DeliveryInfo } isEdit={ isEdit } />}
                {!isMealDealFundraiser && (
                  <>
                    <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                      <Text variant="text.header.form">
                        {t('fundraiser.FundraiserTaxId')}
                      </Text>
                    </Flex>
                    <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                      <Form.Field
                        hint={ t('fundraiser.FederalTaxIdHelp') }
                        id="FederalTaxId"
                        label="Tax Id"
                        name="FederalTaxId"
                        sx={ { marginRight: [ 0, '12px' ], flex: [ '1', '1' ], input: { width: [ '100%', '50%' ] } } }
                        variant="forms.fundraiser.field"
                      />
                    </Flex>
                    <Flex sx={ { flexDirection: [ 'column', 'row' ] } }>
                      <Form.Field
                        component={ Form.Input.Checkbox }
                        defaultChecked={ false }
                        id="TaxAcknowledgement"
                        inline={ true }
                        label={ t('fundraiser.TaxAcknowledgement') }
                        name="TaxAcknowledgement"
                        sx={ { mt: '16px', svg: { color: 'black' } } }
                      />
                    </Flex>
                  </>
                )}
                {isBrochureFundraiser && (
                  <Text sx={ { fontSize: '14px', fontWeight: 'bold', fontFamily: fonts.lato } }>
                    <PhoneNumberLinkWrapper text={ t('fundraiser.summary.deliveryInfo.deliveryChanges') } />
                  </Text>
                )}
                <Button id="fundraisingDetailsNext" sx={ { mt: '33px' } } type="submit">
                  {isEdit ? 'Apply Changes' : t('fundraiser.FundraisingDetailsNext')}
                </Button>

              </Flex>
            </Form>
          </Box>
        </Step>
      )}

      { mode === 'preview' && (
        <Step onEdit={ onEdit } showEdit={ showEdit } title={ t('fundraiser.YourFundraiser', { context: fundraiserType }) }>
          <Box variant="cards.step.body">
            <FundraiserDetailsSummary details={ fundraiserInfo } isBrochure={ fundraiserType === FundraiserType.Brochure } isMealDeal={ fundraiserType === FundraiserType.MealDeal } profitCheckInfo={ profitCheckInfo } />
          </Box>
        </Step>
      )}
    </>
  );
};

export default FundraiserDetails;
