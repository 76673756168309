/* eslint-disable max-lines-per-function */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Form, Flex, Spinner, Text, Input,
} from '@lce/slice_v2';
import { useTranslation } from '@lce/i18n';
import { toast } from 'react-toastify';
import numeral from 'numeral';

import { Step, Mode } from 'ui/common';
import { useFetchFundraiserOrderSummary, useCreateFinalOrder } from 'features/fundraiser/hooks';
import { FundraiserListItem } from 'features/fundraiser/types';
import { FinalOrderFormStepState } from 'features/fundraiser/types/fundraiser';
import { InlineLinkParseAndReplace, SubmitButton } from 'ui/components';
import { useSafeDateFormat } from 'ui/hooks';

import './final-order-form.css';

export type FinalOrderFormProps = {
  fundraiser: FundraiserListItem;
  testId?: string;
  mode: Mode;
  onNext: () => void;
  onEdit: () => void;
}

export const dataTestIdFinalOrderForm = 'final-order-form-component';

export type ManualOrderTotal= {
  productsTotal: Record<number, number>;
  total: number;
}

export const minimumDeliveryKit = 50;

export const FinalOrderForm: React.FC<FinalOrderFormProps> = ({
  fundraiser,
  testId = dataTestIdFinalOrderForm,
  mode,
  onNext,
  onEdit,
}) => {
  const [ showMinOrderError, setShowMinOrderError ] = useState<boolean>(false);
  const [ disableSubmit, setDisableSubmit ] = useState<boolean>(false);
  const [ fundraiserMeetsMinimumKitCount, setFundraiserMeetsMinimumKitCount ] = useState(true);
  const [ t ] = useTranslation();
  const stepTitle = t('dashboard.finalOrderPage.form.header');
  const {
    data: orderSummary,
    isLoading,
    isError: isErrorFetchingOrderSummary,
  } = useFetchFundraiserOrderSummary(fundraiser.Id);

  const [
    brochureOrderCount,
    setBrochureOrderCount,
  ] = useState<ManualOrderTotal>({ productsTotal: {}, total: 0 });

  useEffect(() => {
    const initialBrochureOrderCount = orderSummary?.KitOrders?.reduce((acc, cur) => {
      const total = acc.total + cur.BrochureQuantity;
      const productsTotal = { ...acc.productsTotal, [cur.Id]: cur.BrochureQuantity };

      return { total, productsTotal };
    }, brochureOrderCount) ?? brochureOrderCount;
    setBrochureOrderCount(initialBrochureOrderCount);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ orderSummary ]);

  const { mutateAsync: createFinalOrderMutation } = useCreateFinalOrder();

  useEffect(() => {
    const totalOrderCount = brochureOrderCount.total + (orderSummary?.OnlineKitTotal || 0);
    const meetsMinimumCount = totalOrderCount >= minimumDeliveryKit;
    setFundraiserMeetsMinimumKitCount(meetsMinimumCount);

    if (fundraiserMeetsMinimumKitCount) {
      setDisableSubmit(false);
    }
  }, [
    brochureOrderCount,
    fundraiserMeetsMinimumKitCount,
    orderSummary,
    setDisableSubmit,
    setFundraiserMeetsMinimumKitCount,
  ]);

  const deliveryEndTime = useSafeDateFormat(orderSummary?.DeliveryEndTime, 'MM/dd/yyyy');
  const deliveryStartTime = useSafeDateFormat(orderSummary?.DeliveryStartTime, 'MM/dd/yyyy');

  const onSubmit = useCallback(async() => {
    if (!orderSummary || !fundraiserMeetsMinimumKitCount) {
      setShowMinOrderError(true);
      setDisableSubmit(true);
      return;
    }
    setDisableSubmit(false);
    setShowMinOrderError(false);
    const data = {
      fundraiserId: fundraiser.Id,
      kitOrders: orderSummary.KitOrders.map(kitOrder => ({
        ...kitOrder,
        BrochureQuantity: brochureOrderCount.productsTotal[kitOrder.Id],
      })),
    };

    try {
      await createFinalOrderMutation(data);
      onNext();
    } catch (error) {
      toast.error(t('dashboard.finalOrderPage.form.toast.error'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fundraiserMeetsMinimumKitCount,
    orderSummary,
    brochureOrderCount,
    fundraiser,
    createFinalOrderMutation,
  ]);

  const onProductQuantityChange = useCallback((e) => {
    const { name, value } = e.target;
    const numericValue = value === '' ? '0' : value;
    brochureOrderCount.productsTotal[name] = parseInt(numericValue, 10);
    brochureOrderCount.total = Object.values(brochureOrderCount.productsTotal).reduce((acc, val) => acc + val, 0);

    setBrochureOrderCount({ ...brochureOrderCount });
  }, [ brochureOrderCount ]);

  if (isLoading) {
    return (
      <Flex
        data-testid={ testId }
        sx={ {
          alignItems: 'center', height: '100vh', justifyContent: 'center', width: '100%',
        } }
      >
        <Spinner variant="lce" />
      </Flex>
    );
  }

  if (isErrorFetchingOrderSummary || !orderSummary) {
    toast.error(t('dashboard.finalOrderPage.form.toast.fetchError'));
    return null;
  }

  return (
    <Flex data-testid={ testId } sx={ { gap: '12px', flexDirection: 'column' } }>
      <Text sx={ { fontWeight: 'bold' } }>
        { t('dashboard.finalOrderPage.deliveryRange', { deliveryEndTime, deliveryStartTime }) }
      </Text>
      {mode === FinalOrderFormStepState.Active && (
        <Step title={ stepTitle }>
          <Form onSubmit={ onSubmit }>
            <Flex
              sx={ {
                flexDirection: 'column', gap: [ '16px', '32px' ], p: [ '16px', '32px' ], width: '100%',
              } }
            >
              <table className="final-order-table">
                <thead className="final-order-table__header">
                  <tr>
                    <th colSpan={ 2 } />
                    <th>
                      <Text sx={ { fontSize: '18px', fontWeight: 'bold' } }>
                        {t('dashboard.finalOrderPage.form.headings.product')}
                      </Text>
                    </th>
                    <th>
                      <Text sx={ { fontSize: '18px', fontWeight: 'bold', textAlign: [ 'center', 'left' ] } }>
                        {t('dashboard.finalOrderPage.form.headings.onlineQuantity')}
                      </Text>
                    </th>
                    <th>
                      <Text sx={ { fontSize: '18px', fontWeight: 'bold' } }>
                        {t('dashboard.finalOrderPage.form.headings.brochureQuantity')}
                      </Text>
                    </th>
                    <th />
                  </tr>

                  <tr>
                    <th className="final-order-table__header-cell" colSpan={ 6 } />
                  </tr>
                </thead>
                <tbody>
                  { orderSummary.KitOrders.map(({
                    Id, Code, Color, Price, Name, OnlineQuantity, BrochureQuantity,
                  }) => (
                    <tr key={ Id }>
                      <td />
                      <td>
                        <Flex
                          sx={ {
                            backgroundColor: Color,
                            color: 'secondary',
                            display: 'inline-flex',
                            py: '3px',
                            width: [ '30px', '40px' ],
                            borderRadius: '5px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 5px 0 0',
                          } }
                        >
                          <Text sx={ { fontSize: '14px', fontWeight: 'bold', fontFamily: 'roboto' } }>
                            {Code}
                          </Text>
                        </Flex>
                      </td>
                      <td>
                        <Text
                          sx={ {
                            textWrap: 'balance', whiteSpace: 'pre-line', fontSize: '16px', lineHeight: 'normal',
                          } }
                        >
                          {t('dashboard.finalOrderPage.form.productName',
                            { Name, Code, Price: numeral(Price).format(t('currency.format')) })}
                        </Text>
                      </td>
                      <td>
                        <Text sx={ { fontSize: '16px', textAlign: [ 'center', 'left' ] } }>
                          {OnlineQuantity}
                        </Text>
                      </td>
                      <td>
                        <Input
                          defaultValue={ BrochureQuantity }
                          min={ 0 }
                          name={ Id.toString() }
                          onChange={ onProductQuantityChange }
                          type="number"
                          value={ brochureOrderCount.productsTotal[Name] }
                        />

                      </td>
                      <td />
                    </tr>
                  ))}
                  <tr>
                    <td className="final-order-table__footer-cell" colSpan={ 6 } />
                  </tr>
                  <tr>
                    <td className="final-order-table__footer-label" colSpan={ 2 } />
                    <td className="final-order-table__footer-label">
                      <Text sx={ { fontSize: '18px', fontWeight: 'bold' } }>
                        {t('dashboard.finalOrderPage.form.total')}
                      </Text>
                    </td>
                    <td className="final-order-table__footer-label">
                      <Text sx={ { fontSize: '18px', fontWeight: 'bold' } }>
                        {orderSummary.OnlineKitTotal}
                      </Text>
                    </td>
                    <td className="final-order-table__footer-label">
                      <Text
                        data-testid={ `${ dataTestIdFinalOrderForm }-total` }
                        sx={ { fontSize: '18px', fontWeight: 'bold' } }
                      >
                        {brochureOrderCount.total}
                      </Text>
                    </td>
                    <td className="final-order-table__footer-label" />
                  </tr>
                </tbody>
              </table>
              <Flex sx={ { flexDirection: 'column', gap: '4px' } }>
                <SubmitButton
                  data-testid={ `${ dataTestIdFinalOrderForm }-submit-btn` }
                  disabled={ disableSubmit }
                  sx={ { width: '100%' } }
                  variant={ disableSubmit ? 'disabled' : 'primary.large' }
                >
                  {t('dashboard.finalOrderPage.form.submitButton')}
                </SubmitButton>
                {showMinOrderError && (
                  <Text
                    data-testid={ `${ dataTestIdFinalOrderForm }-min-error-text` }
                    sx={ { fontFamily: 'roboto', fontSize: '14px', color: 'gray' } }
                  >
                    <InlineLinkParseAndReplace
                      text={ t('dashboard.finalOrderPage.form.minOrderError', { minimumDeliveryKit }) }
                    />
                  </Text>
                )}
              </Flex>
            </Flex>
          </Form>
        </Step>
      )}
      {mode === FinalOrderFormStepState.Preview && (
        <Step onEdit={ onEdit } showEdit={ true } title={ stepTitle } />
      )}
    </Flex>
  );
};
